<nav class="h-[41px] relative"
  [ngClass]="{
    'border-b-[1px]': borderBottom,
    'border-tt-header-separator': borderType === 'header',
    'border-tt-border-default': borderType === 'base',
  }"
>
  <ul
    class="h-full flex flex-row"
    [ngClass]="{
      'absolute -left-[16px]': startFromLine
    }"
  >
    <li class="h-full"
      *ngFor="let tab of tabs"
    >
      <button
        class="nav-item"
        [ngClass]="{
          'nav-item__selected': tab.url == selectedId
        }"
        (click)="tabClicked.emit(tab.url)"
        *ngIf="navType == 'click'"
      >
        {{tab.title}}
      </button>

      <a
        class="nav-item"
        *ngIf="navType == 'link'"
        [routerLink]="tab.url"
        [queryParams]="tab.query"
        [queryParamsHandling]="queryParamsHandling"
        routerLinkActive="nav-item__selected"
      >
        {{tab.title}}
      </a>

      <a
        class="nav-item"
        *ngIf="navType == 'query'"
        [routerLink]="'./'"
        [queryParams]="tab.query"
        [queryParamsHandling]="queryParamsHandling"
        [ngClass]="{
          'nav-item__selected': tab.url == selectedId
        }"
        (click)="tabClicked.emit(tab.url)"
      >
        {{tab.title}}
      </a>
    </li>
  </ul>
</nav>
