import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Validator } from "../../../../../../../core/validators/validator";

@Component({
  selector: "app-role-main",
  templateUrl: "./role-main.component.html",
  styleUrls: ["./role-main.component.scss"]
})
export class RoleMainComponent implements OnChanges {
  @Input() isReadOnly: boolean = true;
  @Input() data: RoleMainEntity = {
    name: "",
    description: ""
  };

  @Output() onSave = new EventEmitter<RoleMainEntity>();

  initData: RoleMainEntity = {
    name: "",
    description: ""
  };

  nameError: string | null = null
  descriptionError: string | null = null

  constructor(
    @Inject("NewRoleNameValidator")
    private roleNameValidator: Validator,
    @Inject("NewRoleDescriptionValidator")
    private roleDescriptionValidator: Validator) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']){
      this.initData = {
        name: this.data.name,
        description: this.data.description
      }
      this.decline()
    }
  }

  nameChange(value: string) {
    this.nameError = this.roleNameValidator.validate(value)
    this.data.name = value
  }

  descriptionChange(value: string) {
    this.descriptionError = this.roleDescriptionValidator.validate(value)
    this.data.description = value
  }

  save(){
    this.descriptionError = this.roleDescriptionValidator.validate(this.data.description)
    this.nameError = this.roleNameValidator.validate(this.data.name)

    if(!this.nameError && !this.descriptionError){
      this.onSave.emit(this.data)
    }
  }

  decline(){
    this.data = {
      name: this.initData.name,
      description: this.initData.description,
    }
  }
}

export interface RoleMainEntity{
  name: string,
  description: string,
}
