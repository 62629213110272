<a
  class="
      px-[16px] py-[4px] rounded-tt-border-radius_l
      nav-title-color
      flex gap-[16px] items-center
    "
  routerLink="{{navElement.url}}"
  [matTooltip]="type === 'hide' ? navElement.name :''"
  [matTooltipPosition]="'above'"
  routerLinkActive="bg-tt-side-bar-item-selected-background font-normal text-tt-text-main"
  #rla="routerLinkActive"
  [ngClass]="{
    'hover:bg-tt-hover-nav-sidebar': !rla.isActive,
    'justify-center': type === 'hide'
  }"
>
  <ng-template [ngComponentOutlet]="navElement.icon"></ng-template>
  <span *ngIf="type !== 'hide'" class="text-tt-font-size_l">{{navElement.name}}</span>
</a>
