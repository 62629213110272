import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { NewsSummaryEntity } from "../../../domain/news-summary-entity";
import { ToastState } from "../../../../../../../core/components/toast-alert/toast-alert.component";
import { NewsService } from "../../../../../data/news-service";

@Component({
  selector: "app-news-item",
  templateUrl: "./news-item.component.html",
  styleUrls: ["./news-item.component.scss"]
})
export class NewsItemComponent implements AfterViewInit, OnChanges, AfterViewChecked {
  @Input() type: "grid-cell" | "list-element" = "grid-cell";
  @Input() news: NewsSummaryEntity = {
    id: "",
    title: "",
    description: "",
    dateStr: "",
    longreadId: ''
  };

  @Output() onClick = new EventEmitter<NewsSummaryEntity>();

  @ViewChild("text") text?: ElementRef;
  @ViewChild("description") description?: ElementRef;

  imageUrl: string = '';
  private isViewInit: boolean = false

  constructor(
    private renderer: Renderer2,
    private newsService: NewsService,
  ) {
    if(this.news.imageId)
    {
      newsService.downloadImage(this.news.imageId).subscribe({
        next: (result) => {
          this.imageUrl = URL.createObjectURL(result)
        }
      })
    }
  }

  ngAfterViewChecked(): void {
    this.resizeDescription()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['news'] && this.isViewInit){
      this.resizeDescription()
    }
  }

  ngAfterViewInit(){
    this.isViewInit = true
    this.resizeDescription()
  }

  resizeDescription()
  {
    const textRect = this.text?.nativeElement.getBoundingClientRect();
    const descriptionRect = this.description?.nativeElement.getBoundingClientRect();

    const countDescriptionLines = Math.floor((textRect.height - (descriptionRect.top - textRect.top)) / 18);
    this.renderer.setStyle(this.description?.nativeElement, '-webkit-line-clamp', countDescriptionLines, 1)
  }
}
