import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import { AuthService } from '../domain/auth.service';
import { LoginByPasswordData } from '../domain/login-by-password-data';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpStatusCode,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoginResponseJson } from './json/login-response-json';
import { LoginStatus } from '../domain/login-status';
import { jwtDecode } from 'jwt-decode';
import { AuthorizationNavigator } from '../presentation/navigation/authorization-navigator';
import { CryptUtils } from '../../../core/crypt/crypt-utils';
import { SaltResponseJson } from './json/salt-response-json';
import { Account, Company, User } from '../domain/account';
import { RefreshTokenResponseJson } from './json/refresh-token-response-json';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceImpl implements AuthService {
  private userSubject: BehaviorSubject<Account | null>;
  public userObservable: Observable<Account | null>;

  constructor(
    private http: HttpClient,
    private navigator: AuthorizationNavigator,
  ) {
    this.userSubject = new BehaviorSubject<Account | null>(null);
    this.userObservable = this.userSubject.asObservable();
  }

  getAccount(): Account | null {
    const account = localStorage.getItem('account')
    if(account)
    {
      return JSON.parse(account) as Account;
    }
    return null
  }

  isAuthenticated(): boolean {
    return this.getAccount() != null;
  }

  login(data: LoginByPasswordData): Observable<LoginStatus> {
    return this.http
      .get<SaltResponseJson>(
        `${environment.apiUrl}/authorization/salt/${data.login}`,
        { withCredentials: true },
      )
      .pipe(
        mergeMap((response) => {
          return this.loginRequest(response, data)
        }),
        catchError((err) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status == HttpStatusCode.NotFound
          ) {
            return of(LoginStatus.INCORRECT_CREDENTIALS);
          } else {
            return of(LoginStatus.UNKNOWN);
          }
        }),
      );
  }

  private loginRequest(response: SaltResponseJson, data: LoginByPasswordData) {
    const hashPassword = CryptUtils.toSha256Hash(
      data.password,
      response.salt,
    );
    const body = { email: data.login, password: hashPassword };
    return this.http
      .post<LoginResponseJson>(
        `${environment.apiUrl}/authorization/login`,
        body,
        { withCredentials: true },
      )
      .pipe(
        map((response) => {
          const account = new Account(
            response.accessJwtToken,
            response.user.userId,
          );

          localStorage.setItem('account',  JSON.stringify(account))

          this.userSubject.next(account);
          return LoginStatus.SUCCESS;
        }),
      );
  }

  logout(withoutRequest?: boolean): void {
    if(!withoutRequest)
    {
      this.http
        .post<any>(
          `${environment.apiUrl}/authorization/logout`,
          {},
          { withCredentials: true },
        )
        .subscribe({
          next: (value)=>{
            localStorage.removeItem("account");
            this.userSubject.next(null);
            this.navigator.openLogin();
          },
          error: (err) => {
            localStorage.removeItem("account");
            this.userSubject.next(null);
            this.navigator.openLogin();
          }
        });
    }
    else{
      localStorage.removeItem('account')
      this.userSubject.next(null);
      this.navigator.openLogin(true);
    }
  }

  refreshToken() {
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      observe: 'response' as 'response',
    };

    return this.http
      .get<RefreshTokenResponseJson>(
        `${environment.apiUrl}/authorization/refresh-token`,
        httpOptions,
      )
      .pipe(
        map((response) => {
          const accountStr = localStorage.getItem('account')
          if(accountStr)
          {
            const account = JSON.parse(accountStr) as Account;
            const newAccount = new Account(
              response.body?.accessJwtToken!,
              account.userId
            );

            localStorage.setItem('account',  JSON.stringify(newAccount))

            this.userSubject.next(newAccount);
          }
          else{
            this.logout(true)
          }

          return response;
        }),
      );
  }
}
