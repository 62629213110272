<button
  class="rounded-[16px] w-full h-full p-[2px] transition group flex items-center"
  [ngClass]="{
    'bg-[#EEF1F4]': !isChecked,
    'bg-tt-primary-button': isChecked,
  }"
  (click)="onSwitch.emit()"
>
  <div
    class="h-full my-[2px] aspect-square transition duration-200 rounded-full"
    [ngClass]="{
    'bg-white translate-x-[13px]': isChecked,
    'bg-[#B8BECD]': !isChecked,
  }"
  ></div>
</button>
