export const dateToDMonthYString = (date: Date, shortMonth: boolean = false): string => {
  return `${date.getDate()} ${shortMonth ? SHORT_MONTHS.get(date.getMonth()) : MONTHS.get(date.getMonth())} ${date.getFullYear()}`;
};

const MONTHS = new Map<number, string>()
  .set(0, 'января')
  .set(1, 'февраля')
  .set(2, 'марта')
  .set(3, 'апреля')
  .set(4, 'мая')
  .set(5, 'июня')
  .set(6, 'июля')
  .set(7, 'августа')
  .set(8, 'сентября')
  .set(9, 'октября')
  .set(10, 'ноября')
  .set(11, 'декабря')

const SHORT_MONTHS = new Map<number, string>()
  .set(0, 'янв')
  .set(1, 'фев')
  .set(2, 'мар')
  .set(3, 'апр')
  .set(4, 'мая')
  .set(5, 'июн')
  .set(6, 'июл')
  .set(7, 'авг')
  .set(8, 'сен')
  .set(9, 'окт')
  .set(10, 'ноя')
  .set(11, 'дек')
