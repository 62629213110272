<app-auth-layout>
  <section
    class="relative bg-tt-bg-default rounded-tt-border-radius_l
    sm:rounded-tt-border-radius_4xl mt-4 mb-16 p-[44px] sm:p-tt_8xl
    w-full max-w-tt-auth-container border-[1px] border-tt-border-default"
    (keyup.enter)="performAction({ type: ResetPasswordActionTypes.RESET })"
  >
    <div *ngIf="state.status !== 'success'">
      <h2 class="text-center text-tt-text-main">
        <span class="font-medium text-[24px] leading-[36px]">
          Восстановление пароля
        </span>
        <span class="block mt-[20px] leading-[18px]">
          Укажите почту, с которой вы регистрировались. Мы вышлем ссылку для
          смены пароля.
        </span>
      </h2>

      <app-core-input-field
        className="mt-[20px]"
        placeholder="E-mail"
        type="text"
        [error]="state.emailError.toString()"
        (onChange)="
          performAction({
            type: ResetPasswordActionTypes.CHANGE_EMAIL,
            email: $event
          })
        "
      />

      <app-core-button
        className="w-full mt-6"
        size="xl"
        [loading]="state.isLoading"
        (click)="performAction({ type: ResetPasswordActionTypes.RESET })"
      >
        Восстановить пароль
      </app-core-button>

      <a href="/login" class="block mt-6">
        <app-core-button size="xl" variant="soft" className="w-full">
          Назад
        </app-core-button>
      </a>

    </div>

    <div *ngIf="state.status === 'success'">
      <p class="text-center text-tt-text-main">
        <span class="font-medium text-[24px] leading-[36px]">Проверьте почту</span>
        <span class="block mt-[20px] leading-[18px]">
          Ссылка для смены пароля отправлена на
          <span class="text-tt-secondary">{{ state.email }}</span>
        </span>
      </p>

      <a href="/login" class="block mt-6">
        <app-core-button size="xl" className="w-full">
          Перейти ко входу
        </app-core-button>
      </a>
    </div>

    <div
      class="absolute left-0 bottom-[-44px] w-full flex justify-center items-center mt-[20px]"
    >
      <a class="text-tt-text-secondary text-tt-font-size_l" href="https://teamtells.ru">
        teamtells.ru
      </a>
    </div>
  </section>
</app-auth-layout>
