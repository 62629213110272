import { Injectable } from '@angular/core';
import { SubscriptionEntity, TariffType } from "../../domain/subscription-entity";
import { TariffEntity } from "../../domain/tariff-entity";
import { TariffDuration } from "../../domain/tariff-duration";

@Injectable()
export class PaymentAndTariffState {
  readonly isEditable: boolean = false;
  readonly isLoading: boolean = false;

  readonly isSubscriptionFormOpen: boolean = false;
  readonly isTariffFormOpen: boolean = false;

  readonly currentDuration: TariffDuration | null = null;
  readonly durationItems: TariffDuration[] = [
    // {
    //   id: 'month',
    //   name: 'На месяц',
    //   countMonth: 1,
    // },
    // {
    //   id: 'three-month',
    //   name: 'На 3 месяца',
    //   countMonth: 3,
    // },
    // {
    //   id: 'half-year',
    //   name: 'На полгода',
    //   countMonth: 6,
    // },
    // {
    //   id: 'year',
    //   name: 'На год',
    //   countMonth: 12,
    //   discountPercent: 20
    // },
  ]

  readonly currentTariff?: TariffEntity
  readonly tariffs: TariffEntity[] = [
    // {
    //   id: TariffType.BASE,
    //   title: 'Базовый',
    //   prices: 199,
    //   features: [
    //     {
    //       title: 'База знаний',
    //       soon: false
    //     },
    //     {
    //       title: 'Сотрудники',
    //       soon: false
    //     },
    //     {
    //       title: 'Управление доступами',
    //       soon: false
    //     },
    //     {
    //       title: 'Стандартные шаблоны',
    //       soon: true
    //     },
    //   ]
    // },
    // {
    //   id: TariffType.COMMAND,
    //   title: 'Командный',
    //   prices: 399,
    //   prevTariffFeaturesStr: 'Все из Базового тарифа, плюс',
    //   features: [
    //     {
    //       title: 'Лента новостей',
    //       soon: false
    //     },
    //     {
    //       title: 'Управление проектами',
    //       soon: true
    //     },
    //     {
    //       title: 'Agile-доски',
    //       soon: true
    //     },
    //     {
    //       title: 'Диск',
    //       soon: true
    //     },
    //     {
    //       title: 'Рабочий календарь',
    //       soon: true
    //     },
    //   ]
    // },
    // {
    //   id: TariffType.BUSINESS,
    //   title: 'Бизнес',
    //   prices: 699,
    //   prevTariffFeaturesStr: 'Все из Командного тарифа, плюс',
    //   features: [
    //     {
    //       title: 'Сайт в вашем корпоративном стиле',
    //       soon: true
    //     },
    //     {
    //       title: 'Приоритетная поддержка',
    //       soon: false
    //     },
    //     {
    //       title: 'Авторизация через SSO',
    //       soon: true
    //     },
    //     {
    //       title: 'Интеграции с различными внешними сервисами',
    //       soon: true
    //     },
    //     {
    //       title: 'Почтовый клиент',
    //       soon: true
    //     },
    //   ]
    // },
    // {
    //   id: TariffType.CORPORATE,
    //   title: 'Корпоративный',
    //   prices: 'contact',
    //   prevTariffFeaturesStr: 'Все из Бизнес тарифа, плюс',
    //   features: [
    //     {
    //       title: 'Индивидуальные условия и корректирование дорожной карты разработки',
    //       soon: false
    //     },
    //     {
    //       title: 'Возможность размещения на локальном сервере',
    //       soon: false
    //     },
    //   ]
    // },
  ]

  readonly subscription?: SubscriptionEntity = {
    tariff: {
      id: '',
      name: ''
    },
    expirationDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
    billingPeriodStart: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 60),
    billingPeriodEnd: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
    activeUsersCount: 1,
    activeUsersMax: 25,
    occupiedStorageSpace: 0.04,
    maxStorageSpace: 25
  }
}
