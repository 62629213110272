<div
  class="w-full flex flex-col"
>
  <app-toggler
    [selectedId]="currentDuration?.countMonth + ''"
    [toggleItems]="durationToggleItems"
    (toggle)="toggleTariffType($event)"
  />

  <div class="mt-[16px] w-full flex gap-[16px] flex-wrap">
    <div
      class="min-h-[492px] max-w-[258px] w-full rounded-[16px] border border-tt-border-default p-[8px] bg-white text-left"
      *ngFor="let tariff of tariffs"
    >
      <div class="w-full rounded-[12px] p-[16px] bg-[#F0F5FC] text-tt-text-main h-[180px]">
        <p class="text-tt-font-size_3xl font-medium">{{ tariff.title }}</p>
        <div>
          <p class="mt-[12px] text-tt-font-size_3xl font-medium">{{ calcCurrentPrice(currentDuration, tariff.prices) }} ₽</p>
          <p class="mt-[8px] text-tt-font-size_m text-tt-text-secondary">за пользователя в месяц</p>
        </div>

        <a
          [routerLink]="'./'"
          [queryParams]="{'open':'subscription'}"
        >
          <app-core-button className="mt-[16px] w-full">
            <span *ngIf="selectedTariff?.id === tariff.id">Подключен</span>
            <span *ngIf="selectedTariff == undefined">Подключить</span>
            <span *ngIf="selectedTariff != undefined && selectedTariff.id !== tariff.id">Обновить</span>
          </app-core-button>
        </a>

      </div>

      <div class="flex flex-col gap-[12px] text-tt-font-size_l mt-[16px] text-tt-text-main">
        <p
          *ngIf="tariff.prevTariffFeaturesStr"
          class="font-medium"
        >{{ tariff.prevTariffFeaturesStr }}</p>

        <div
          *ngFor="let feature of tariff.features"
          class="flex gap-[8px] pl-[8px]"
        >
          <svg-feature-checkmark/>
          <p>{{feature.title}} <span *ngIf="feature.soon" class="text-tt-text-secondary">(Скоро)</span></p>
        </div>
      </div>

    </div>
  </div>

</div>
