<div
  class="py-[44px] max-w-[1256px] w-full mx-auto"
>
  <div class="flex items-center w-full justify-between gap-[16px]">
    <p class="block-title">Активная подписка</p>
    <a
      [routerLink]="'./'"
      [queryParams]="{'open':'tariff'}"
      *ngIf="state.subscription"
      class="text-tt-font-size_l text-tt-primary transition-opacity hover:opacity-80"
    >Продлить и расширить</a>
  </div>

  <div
    class="mt-4"
  >
    <app-active-subscription
      [subscription]="state.subscription"
    />
  </div>

  <p class="mt-[24px] mb-[16px] block-title">Тарифы</p>

  <app-tariff-list
    [selectedTariff]="state.currentTariff"
    [tariffs]="state.tariffs"
    [durationItems]="state.durationItems"
    [currentDuration]="state.currentDuration"
  />
</div>

<app-active-subscription-form
  *ngIf="state.isSubscriptionFormOpen"
/>

<app-tariff-form
  *ngIf="state.isTariffFormOpen && state.subscription"
/>
