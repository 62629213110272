import { TariffDuration } from "../../domain/tariff-duration";
import { TariffCostsDto } from "../../../../data/dto/all-tariffs-dto";

export function calcCurrentPrice(
  duration: TariffDuration | null,
  prices: TariffCostsDto[]
): number{
  if (prices.length == 0) return 0;

  if (duration == null) return prices[0].value

  const findPrice = prices.find((price) => price.duration == duration.countMonth)

  if(findPrice) return findPrice.value * (1 - duration.discountPercent / 100)

  return 0
}
