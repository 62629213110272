<button
  type="button"
  class="h-full w-full justify-center flex items-center group"
  [disabled]="inactive"
  (click)="onToggleValue()"
>
  <div
    class="flex items-center justify-center h-full aspect-square rounded-full"
    [ngClass]="{
      'text-tt-icon-secondary': inactive && inactiveStyle === 'gray',
      'text-tt-primary': !inactive || inactiveStyle === 'base',
      'group-hover:bg-tt-button-hover': !inactive && hoverType === 'base',
      'group-hover:bg-[#EEF2FC]': !inactive && hoverType === 'blue',
    }"
  >
    <svg-checkmark-fill
      class="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] flex items-center justify-center"
      *ngIf="value"
    />

    <svg-checkbox-empty
      *ngIf="!value"
      class="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] text-tt-icon-secondary flex items-center justify-center"
    />

  </div>
  <p
    *ngIf="label != ''"
    class="ms-2 text-tt-font-size_l text-tt-text-main"
  >
    {{ label }}
  </p>
</button>
