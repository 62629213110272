<div class="flex flex-col gap-[16px]">
  <app-core-input-field
    label="Фамилия"
    variant="standard"
    placeholder=""
    error="{{ surnameError }}"
    [defaultValue]="mainEmployeeData.surname"
    [value]="mainEmployeeData.surname"
    [required]="true"
    (onChange)="surnameChange($event)"
  />

  <div class="flex gap-[16px] w-full">
    <app-core-input-field
      label="Имя"
      variant="standard"
      placeholder=""
      error="{{ nameError }}"
      [defaultValue]="mainEmployeeData.name"
      [value]="mainEmployeeData.name"
      [required]="true"
      (onChange)="nameChange($event)"
    />
    <app-core-input-field
      label="Отчество"
      variant="standard"
      error="{{patronymicError}}"
      placeholder=""
      [defaultValue]="mainEmployeeData.patronymic"
      [value]="mainEmployeeData.patronymic"
      (onChange)="patronymicChange($event)"
    />
  </div>

  <div class="flex">
    <div class="max-w-[131px]">
      <app-core-input-field
        label="Дата рождения"
        variant="standard"
        placeholder=""
        mask="00.00.0000"
        error="{{ birthdayDateError }}"
        [defaultValue]="birthdayDate"
        [value]="birthdayDate"
        [required]="true"
        (onChange)="dateInputChange($event)"
      >
        <button class="ml-[2px] p-[3px]"
          (click)="isCalendarVisible = !isCalendarVisible"
          #calendarButton
        >
          <svg-calendar />
        </button>
      </app-core-input-field>
    </div>
    <div class="relative">
      <mat-card class="absolute w-[300px] -top-[140px] datepicker-container"
        *ngIf="isCalendarVisible"
        #calendar
      >
        <mat-calendar
          (selectedChange)="birthDayChange($event)"
          [(selected)]="selectedDate"
          [startAt]="selectedDate"
        >
        </mat-calendar>
        <div class="flex justify-end gap-[16px] px-[8px] pb-[8px]">
          <app-core-button
            variant="soft"
            (onClick)="datepickerClose()"
          >
            Cancel
          </app-core-button>
          <app-core-button
            (onClick)="dateApply()"
          >
            Apply
          </app-core-button>
        </div>
      </mat-card>
    </div>

    <div class="w-full flex flex-col ml-[16px] mt-[3px]">
      <span class="mb-[8px] text-tt-text-secondary text-[14px] font-normal leading-[18px]">
        Пол
      </span>
      <div class="
        w-full h-[42px] rounded-tt-border-radius_m
        flex
        border-tt-border-default border-[1px]
        overflow-hidden
        text-[14px] font-normal leading-[18px] text-tt-text-default"
      >
        <button class="
          w-full max-w-[123px]
          flex justify-center items-center "
          *ngFor="let gender of GENDERS; let i = index"
          (click)="selectGender(gender.id)"
          [ngClass]="{
            'bg-tt-bg-secondary': gender.id == mainEmployeeData.gender,
            'border-r-[1px] border-tt-border-default': i != GENDERS.length -1
          }"
        >
          {{ gender.name }}
        </button>
      </div>
    </div>
  </div>
</div>
