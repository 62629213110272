import { OrderEntity } from "../../domain/order-entity";

export type OrdersResultAction =
  | InitResultAction
  | ChangeCancelModalVisibleResultAction

export enum OrdersResultActionTypes {
  INIT,
  CHANGE_CANCEL_MODAL_VISIBILITY,
}

export interface ChangeCancelModalVisibleResultAction {
  readonly type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY
  readonly value: boolean
}


export interface InitResultAction {
  readonly type: OrdersResultActionTypes.INIT
  readonly activeOrder?: OrderEntity
  readonly storyOrders: OrderEntity[]
}
