<table
  class="w-full bg-[#E5ECF9]"
  #tableEl
>
  <colgroup>
    <col
      *ngFor="let col of table.columns"
      [class]="'w-[calc(100%_/_' + table.columns.length + ')]'"
      [class.w-[64px]]="!col.isOpen"
    >
  </colgroup>

  <thead
    class="h-[32px]"
  >
    <tr>
      <th
        *ngFor="let col of table.columns"
      >
        <div class="flex items-center">
          <button
            class="arrow-button text-tt-text-secondary shrink-0"
            (click)="col.isOpen = !col.isOpen"
          >
            <svg-arrow
              class="arrow"
              [class.rotate-180]="col.isOpen"
            />
          </button>
          <span class="line-clamp-1 text-tt-font-size_l font-normal text-tt-text-default">{{col.name}}</span>
        </div>
      </th>
    </tr>
  </thead>

  <tbody
    *ngFor="let row of table.rows"
  >
    <tr>
      <td>
        <div class="h-[38px] relative">
          <div
            class="absolute flex justify-between items-center h-[38px] pr-[8px]"
            #rowHeader
          >
            <div class="flex items-center">
              <button
                class="arrow-button"
                (click)="row.isOpen = !row.isOpen"
              >
                <svg-arrow
                  class="arrow"
                  [class.rotate-90]="row.isOpen"
                />
              </button>
              <span class="text-tt-font-size_l font-semibold text-tt-text-default">{{row.executor.surname + ' ' + row.executor.name }}</span>
            </div>

            <p class="text-tt-font-size_s text-tt-text-secondary">{{row.countTasks + ' ' + ruNumWord(row.countTasks, AgilesConstants.CARD_STRINGS)}}</p>
          </div>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="row.isOpen"
      class="align-top pb-[24px]"
      cdkDropListGroup
    >
      <td
        *ngFor="let columnTasks of row.columnsTasks; let colIndex = index;"
        [ngClass]="{
          'border-r border-tt-border-default': colIndex != row.columnsTasks.length - 1
        }"
        class="table-fixed example-list pb-[24px] group"
        cdkDropList
        [cdkDropListData]="columnTasks"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="h-full w-full box-border relative flex flex-col gap-[6px] py-[3px] px-[8px]"
        >
          <app-task-item
            *ngFor="let task of columnTasks"
            cdkDrag
            [cdkDragData]="task"
            class="example-box"
            [task]="task"
            [executor]="row.executor"
            [isSelected]="isTaskSelected(task)"
            (ctrlClicked)="addSelectedTask(task)"
            [showType]="table.columns[colIndex].isOpen ? 'base' : 'hide'"
          >
            <div
              class="example-custom-placeholder"
              *cdkDragPlaceholder
            ></div>
          </app-task-item>

          <button
            class="absolute bottom-[-24px] text-tt-text-secondary hover:text-tt-primary flex items-center"
            (click)="pushNew(columnTasks)"
          >
            <svg-plus class="size-[24px]"/>
            <p class="text-tt-font-size_s hidden group-hover:block">Новая карточка</p>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div
  class="absolute bottom-0 w-full px-[24px] pt-[8px] pb-[24px] flex justify-between gap-[16px] bg-white"
  *ngIf="selectedTaskEntities.length > 0"
>
  <div class="flex gap-[16px] items-center">
    <p class="text-tt-text-default text-tt-font-size_l">
      {{selectedTaskEntities.length + ' ' + ruNumWord(selectedTaskEntities.length, AgilesConstants.CARD_STRINGS)}}
      {{selectedTaskEntities.length === 1 ? AgilesConstants.SELECTED_STRINGS[0] : AgilesConstants.SELECTED_STRINGS[1] }}
    </p>
    <app-core-button
      variant="soft"
      (onClick)="unSelect()"
    >
      Отменить
    </app-core-button>
  </div>
  <div
    class="rounded-[4px] border border-tt-border-default flex"
  >
    <button
      class="flex justify-center items-center w-[56px] h-[40px] shrink-0"
      (click)="deleteSelected()"
    >
      <svg-trash
        class="size-[24px] shrink-0 text-tt-delete-button"
      />
    </button>
  </div>
</div>
