import { AfterViewInit, Component, HostListener, ViewChild } from "@angular/core";
import { Store } from "../../../../../core/mvi/store";
import { DocumentState } from "../state/document-state";
import { DocumentExecutor } from "../state/document-executor";
import { DocumentAction, DocumentActionTypes } from "../state/document-action";
import { DocumentResultAction } from "../state/document-result-action";
import { DocumentReducer } from "../state/document-reducer";
import { YooptaEditorComponent } from "../../../../../core/components/yoopta-editor/yoopta-editor.component";
import { SpaceConstants } from "../../../../knowledge-base/modules/space/presentation/common/space-constants";
import { environment } from "../../../../../../environments/environment";
import { AuthService } from "../../../../authorization/domain/auth.service";

@Component({
  selector: "app-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.scss"],
  providers: [DocumentState, DocumentExecutor, DocumentReducer]
})
export class DocumentComponent extends Store<
  DocumentState,
  DocumentExecutor,
  DocumentAction,
  DocumentResultAction
> implements AfterViewInit{

  @ViewChild('editor') editor?: YooptaEditorComponent;

  constructor(
    state: DocumentState,
    executor: DocumentExecutor,
    reducer: DocumentReducer,
    private authService: AuthService,
  ) {
    super(state, executor, reducer);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault()
    return 'Вы уверены что хотите закрыть страницу? Ваши изменения могут не сохранится'
  }

  ngAfterViewInit(): void {
    this.performAction({
      type: DocumentActionTypes.INIT_CHILDREN,
      editor: this.editor
    })
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    event.preventDefault()
    this.performAction({
      type: DocumentActionTypes.CLOSE_SESSION
    })
  }

  @HostListener('window:unload', [])
  sendRequest(){
    const account = this.authService.getAccount()
    if(account)
    {
      fetch(`${environment.apiUrl}/session/close/${this.state.sessionId}`, {
          keepalive: true,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${account.jwtToken}`
        },
      })
    }
  }

  @HostListener('document:keydown.control.s', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
    this.performAction({
      type: DocumentActionTypes.SAVE_CLICKED,
    })
    return false
  }

  protected readonly DocumentActionTypes = DocumentActionTypes;
  protected readonly SpaceConstants = SpaceConstants;
}
