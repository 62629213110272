import { TariffFormState } from "./tariff-form-state";
import { TariffFormAction, TariffFormActionTypes } from "./tariff-form-action";
import { TariffFormResultAction, TariffFormResultActionTypes } from "./tariff-form-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { DropdownItem } from "../../../../../../../../core/components/fields/dropdown-field/dropdown-field.component";
import { ReceiptService } from "../../../../data/receipt-service";
import { TariffDuration } from "../../../../domain/tariff-duration";
import { convertDurationToDropDownItem, convertToDropDownItem } from "../../../utils/convert-tariff-duration";
import { ReceiptField, ReceiptSection } from "../../../../domain/receipt-secton";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { Location } from "@angular/common";
import { ruNumWord } from "../../../../../../../../core/utils/ru-num-word";
import { SettingsConstants } from "../../../../../../common/settings-constants";
import { DurationDto, TariffCostsDto } from "../../../../../../data/dto/all-tariffs-dto";
import {
  AdditionalTariff,
  UpdateInvoiceExpandData,
  UpdateInvoiceExtendData
} from "../../../../../../data/dto/get-update-invoice-data-dto";
import { SubscriptionService } from "../../../../../../data/subscription.service";

@Injectable()
export class TariffFormExecutor extends Executor<
  TariffFormState,
  TariffFormAction,
  TariffFormResultAction
> {

  constructor(
    private receiptService: ReceiptService,
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
    private location: Location
  ) {
    super();
  }

  override init(
    reducer: Reducer<TariffFormState, TariffFormResultAction>,
    getState: () => TariffFormState,
    onReduced: (state: TariffFormState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: TariffFormAction) {
    switch (action.type) {
      case TariffFormActionTypes.CHANGE_DURATION:
        const findDuration = this.getState().durationDropDownItems.find(tariff => tariff.id === action.durationId)
        if(findDuration) {
          this.reduce({
            type: TariffFormResultActionTypes.CHANGE_DURATION,
            duration: findDuration
          })
        }
        break
      case TariffFormActionTypes.CHANGE_ADDITIONAL_MEMORY:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_USERS_COUNT:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_USERS_COUNT,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_IS_DURATION_OPEN:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_DURATION_OPEN,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_IS_EXTENSIONS_OPEN:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_EXTENSIONS_OPEN,
          value: action.value
        })
        break
    }
    this.updateReceipt()
  }

  private handleInit() {
    this.reduce({
      type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    this.subscriptionService.getUpdateInvoiceData().subscribe({
      next: (dto) => {
        let currentDuration: DropdownItem | null = null;
        const durations = dto.extendData.durations.map<DropdownItem>(
          (duration, i) => {
            const result = convertDurationToDropDownItem(duration)
            if (i === 0){
              currentDuration = result
            }
            return result
          }
        )

        setTimeout(()=>{
          this.reduce({
            type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
            value: false
          })

          this.reduce({
            type: TariffFormResultActionTypes.INIT,
            expandData: dto.expandData,
            extendData: dto.extendData,
            additionalTariff: dto.additionalTariff,
            durationDropDownItems: durations,
            currentDuration: currentDuration
          })

          setTimeout(() => this.updateReceipt())
        }, 500)

      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке данных для продления подписки',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
        this.location.back()
      }
    })
  }

  private updateReceipt(){
    const state = this.getState()
    const expandData = state.expandData
    const extendData = state.extendData
    const additionalTariff = state.additionalTariff
    const currentDuration = state.currentDuration
    if(expandData && extendData && currentDuration && additionalTariff)
    {
      const findDuration = extendData.durations.find(dur => dur.duration === parseInt(currentDuration.id))
      let receiptSections: ReceiptSection[] = []
      if(findDuration)
      {
        if(state.isExtensionsOpen){
          this.addReceiptExtensionsSection(
            receiptSections,
            state.usersCount,
            state.memoryCount,
            expandData,
            additionalTariff
          )
        }
        if(state.isDurationOpen){
          this.addReceiptExpandSection(
            receiptSections,
            state.usersCount,
            state.memoryCount,
            extendData,
            additionalTariff,
            state.isExtensionsOpen,
            currentDuration,
            findDuration
          )
        }
      }

      this.receiptService.updateSections(receiptSections)
    }
  }

  private addReceiptExpandSection(
    receiptSections: ReceiptSection[],
    usersCount: number,
    memoryCount: number,
    extendData: UpdateInvoiceExtendData,
    additionalData: AdditionalTariff,
    isExtensionsOpen: boolean,
    currentDuration: DropdownItem,
    findDuration: DurationDto
  ){
    let fields: ReceiptField[] = []
    const currentUsersCount = isExtensionsOpen ? usersCount + extendData.currentUsersCount : extendData.currentUsersCount
    const currentMemoryCount = isExtensionsOpen ? memoryCount + extendData.currentAdditionalStorageSize : extendData.currentAdditionalStorageSize

    const price = extendData.costs.find((price) => price.duration == findDuration?.duration)

    if(price)
    {
      fields.push({
        text: `Тариф «${
          extendData.currentTariffName}» ${currentDuration.name} для ${
          currentUsersCount} ${ruNumWord(currentUsersCount, SettingsConstants.USER_STRINGS)} `,
        price: price.value * currentUsersCount * findDuration.duration
      })
      if(findDuration.discount){
        fields.push({
          text: `Скидка ${findDuration.discount}% за продление ${currentDuration.name}`,
          price: -Math.round(price.value * currentUsersCount * findDuration.duration * findDuration.discount / 100)
        })
      }
    }

    const expandStorageCost = additionalData.storageCosts.find((cost) => cost.minCount <= currentMemoryCount && currentMemoryCount <= cost.maxCount)

    if(expandStorageCost){
      fields.push({
        text: `Место на диске, ${
          currentMemoryCount} ГБ`,
        price: expandStorageCost.value * findDuration.duration * currentMemoryCount
      })
    }

    if(fields.length > 0){
      receiptSections.push({
        title: 'Продление',
        fields: fields
      })
    }
  }

  private addReceiptExtensionsSection(
    receiptSections: ReceiptSection[],
    usersCount: number,
    memoryCount: number,
    expandData: UpdateInvoiceExpandData,
    additionalData: AdditionalTariff
  ){
    let fields: ReceiptField[] = []
    if(usersCount > 0){
      fields.push({
        text: `Дополнительны${
          usersCount == 1? 'й': 'ые'} ${
          usersCount} пользовател${
          usersCount % 10 == 1
          && usersCount % 100 != 11 ? 'ь': 'ей'} до ${
          15}.${12}.${2025}`,//TODO поменять на дату
        price: expandData.userCost * expandData.monthCountToExpirationDate * usersCount
      })
    }
    if(memoryCount > 0){
      const extensionStorageCost = additionalData.storageCosts.find(
        (cost) => cost.minCount <= memoryCount && memoryCount <= cost.maxCount
      )
      if(extensionStorageCost)
      {
        fields.push({
          text: `Место на диске, ${
            memoryCount} ГБ до ${
            15}.${12}.${2025}`,//TODO поменять на дату`,
          price: extensionStorageCost.value  * expandData.monthCountToExpirationDate * memoryCount
        })
      }
    }
    if(fields.length > 0){
      receiptSections.push({
        title: 'Расширение',
        fields: fields
      })
    }
  }

  private monthsBetween(date1: Date, date2: Date): number {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    const monthsDifference = (year2 - year1) * 12 + (month2 - month1);

    return Math.abs(monthsDifference);
  }
}
