export interface InvoicesListDto{
  activeInvoice?: InvoiceDto
  invoices: InvoiceDto[]
}

export interface InvoiceDto{
  id: string,
  createdAt: number,
  paymentType: PaymentType,
  totalValue: number,
  status: InvoiceStatus,
  details: string,
  payment?: Payment,
  doc?: DocFile
}

export enum PaymentType {CARD = "byCard", INVOICE = "byInvoice"}
export enum InvoiceStatus {CANCELED = "cancelled", PAID = "paid", PENDING = "pending"}

export interface Payment{
  id: string,
  confirmation: PaymentConfirmation
}

export interface PaymentConfirmation{
  conformationUrl: string
}

export interface DocFile{
  id: string,
  name: string,
  size: number,
  fileType: string
}
