import { OrdersState } from "./orders-state";
import { OrdersAction, OrdersActionTypes } from "./orders-action";
import { OrdersResultAction, OrdersResultActionTypes } from "./orders-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { SubscriptionService } from "../../../../data/subscription.service";
import { InvoiceDto } from "../../../../data/dto/invoices-list-dto";
import { OrderEntity } from "../../domain/order-entity";

const MOCK_DETAILS = [{
  "title": "Расширение",
  "fields": [
    {
      "text": "Дополнительныые 10 пользователей до 7.10.2024",
      "price": 5970
    },
    {
      "text": "Место на диске, 10 ГБ до 7.10.2024",
      "price": 750
    }
  ]
},
  {
    "title": "Продление",
    "fields": [
      {
        "text": "Тариф «Базовый» на год для 35 пользователей ",
        "price": 83580
      },
      {
        "text": "Скидка 20% за продление на год",
        "price": -16716
      },
      {
        "text": "Место на диске, 35 ГБ",
        "price": 10500
      }
    ]
  }]

@Injectable()
export class OrdersExecutor extends Executor<
  OrdersState,
  OrdersAction,
  OrdersResultAction
> {

  constructor(
    private subscriptionService: SubscriptionService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<OrdersState, OrdersResultAction>,
    getState: () => OrdersState,
    onReduced: (state: OrdersState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: OrdersAction) {
    switch (action.type) {
      case OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE:
        this.reduce({
          type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY,
          value: action.value
        })
        break;
    }
  }

  private handleInit(){
    this.subscriptionService.getInvoicesList().subscribe({
      next: (dto) => {
        this.reduce({
          type: OrdersResultActionTypes.INIT,
          activeOrder: dto.activeInvoice ? this.mapToOrderEntity(dto.activeInvoice) : undefined,
          storyOrders: dto.invoices.map((invoice) => this.mapToOrderEntity(invoice))
        })
      }
    })
  }

  private mapToOrderEntity(order: InvoiceDto): OrderEntity{
    return {
      id: order.id,
      createdAt: new Date(order.createdAt * 1000),
      paymentType: order.paymentType,
      totalValue: order.totalValue,
      status: order.status,
      receiptSections: MOCK_DETAILS,
    }
  }
}
