import { Injectable } from '@angular/core';
import {
  AdditionalTariff,
  UpdateInvoiceExpandData,
  UpdateInvoiceExtendData
} from "../../../../../../data/dto/get-update-invoice-data-dto";
import { DropdownItem } from "../../../../../../../../core/components/fields/dropdown-field/dropdown-field.component";

@Injectable()
export class TariffFormState {
  readonly isLoading: boolean = false;

  readonly isExtensionsOpen: boolean = true
  readonly isDurationOpen: boolean = true

  readonly usersCount: number = 0
  readonly memoryCount: number = 0

  readonly currentDuration: DropdownItem | null = null
  readonly durationDropDownItems: DropdownItem[] = []

  readonly expandData: UpdateInvoiceExpandData | null = null
  readonly extendData: UpdateInvoiceExtendData | null = null
  readonly additionalTariff: AdditionalTariff | null = null
}
