import { SpaceNavItems } from "../../domain/nav-items";
import { DocumentSummaryEntity } from "../../domain/document-summery-entity";
import { SectionSummaryEntity } from '../../domain/section-summery-entity';
import { SpaceDtoPermissions } from "../../../../data/dto/space-dto";
import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";

export type SpaceResultAction =
  | ChangeVisibleCreateSpaceModalResultAction
  | FilterContentResultAction
  | UpdateSpaceResultAction
  | UpdateContentResultAction
  | ChangeIsContentLoadingResultAction
  | ChangeIsSpaceLoadingResultAction
  | OpenDocumentResultAction
  | UpdateSpaceTitleResultAction
  | DocumentStartLoadResultAction
  | DocumentEndLoadResultAction
  | InitSelectedDocumentResultAction
  | ChangeIsOpenDeleteDocumentModalResultAction
  | ChangeRenameModalVisibilityResultAction
  | ChangeAlertNameResultAction
  | ChangeAlertIsLoadingResultAction
  | ChangeDocumentListVisibleResultAction
  | ChangeIsPageLoadingResultAction
  | ChangeIsContentFirstLoadingResultAction

export enum SpaceResultActionTypes {
  CHANGE_NAV_ITEM,
  FILTER_CONTENT,
  UPDATE_SPACE,
  UPDATE_CONTENT,
  CHANGE_IS_SPACE_LOADING,
  CHANGE_IS_CONTENT_LOADING,
  CHANGE_IS_CONTENT_FIRST_LOADING,
  OPEN_DOCUMENT,
  UPDATE_SPACE_TITLE,
  DOCUMENT_START_LOAD,
  DOCUMENT_END_LOAD,
  INIT_SELECTED_DOCUMENT,
  CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL,
  CHANGE_RENAME_MODAL_VISIBILITY,
  CHANGE_ALERT_NAME,
  CHANGE_ALERT_IS_LOADING,
  CHANGE_DOCUMENT_LIST_VISIBLE,
  CHANGE_IS_PAGE_LOADING,
}

export interface ChangeIsPageLoadingResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_IS_PAGE_LOADING,
  readonly value: boolean
}

export interface ChangeDocumentListVisibleResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE,
  readonly value: boolean
}

export interface ChangeAlertIsLoadingResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
  readonly value: boolean
}

export interface ChangeAlertNameResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_ALERT_NAME,
  readonly value: string
}

export interface ChangeRenameModalVisibilityResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
  readonly value: boolean,
  readonly id?: string,
  readonly name?: string,
}

export interface ChangeIsOpenDeleteDocumentModalResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL
  readonly id?: string
  readonly name?: string
  readonly value: boolean
}

export interface InitSelectedDocumentResultAction {
  readonly type: SpaceResultActionTypes.INIT_SELECTED_DOCUMENT,
}

export interface DocumentStartLoadResultAction {
  readonly type: SpaceResultActionTypes.DOCUMENT_START_LOAD,
}

export interface DocumentEndLoadResultAction {
  readonly type: SpaceResultActionTypes.DOCUMENT_END_LOAD,
  readonly updatedAt: string
}

export interface UpdateSpaceTitleResultAction {
  readonly type: SpaceResultActionTypes.UPDATE_SPACE_TITLE
  readonly title: string
}

export interface OpenDocumentResultAction {
  readonly type: SpaceResultActionTypes.OPEN_DOCUMENT
  readonly document: DocumentSummaryEntity
  readonly documents: DocumentSummaryEntity[]
  readonly sections: SectionSummaryEntity[]
}

export interface ChangeIsContentLoadingResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING
  readonly value: boolean
}

export interface ChangeIsContentFirstLoadingResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_IS_CONTENT_FIRST_LOADING,
  readonly value: boolean
}

export interface ChangeIsSpaceLoadingResultAction {
  readonly type: SpaceResultActionTypes.CHANGE_IS_SPACE_LOADING
  readonly value: boolean
}

export interface UpdateContentResultAction {
  readonly type: SpaceResultActionTypes.UPDATE_CONTENT
  readonly sections: SectionSummaryEntity[]
  readonly documents: DocumentSummaryEntity[]
}

export interface UpdateSpaceResultAction {
  readonly type: SpaceResultActionTypes.UPDATE_SPACE
  readonly id: string
  readonly permissions: SpaceDtoPermissions
  readonly name: string
  readonly navTabs: NavTab[]
}

export interface ChangeVisibleCreateSpaceModalResultAction{
  readonly type: SpaceResultActionTypes.CHANGE_NAV_ITEM;
  readonly navItem: SpaceNavItems
}

export interface FilterContentResultAction {
  readonly type: SpaceResultActionTypes.FILTER_CONTENT;
  readonly searchStr: string,
  readonly filteredSections: SectionSummaryEntity[],
  readonly filteredDocuments: DocumentSummaryEntity[],
}

