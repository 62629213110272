import { Elements, UI, useYooptaEditor } from "@teamtells/editor";
import React, { ChangeEvent, CSSProperties, useState } from "react";
import { getProvider, ProviderGetters } from "../utils/providers";
import { VideoPluginElement } from "../types/video-plugin-element";
import { VideoElementProps } from "../types/vide-element-props";

const { Overlay, Portal } = UI;

type Props = {
  floatingStyles: CSSProperties;
  refs: any;
  blockId: string;
  onClose: () => void;
  onSetLoading: (_s: boolean) => void;
};

const VideoUploader = ({ floatingStyles, refs, onClose, blockId, onSetLoading }: Props) => {
  const editor = useYooptaEditor();
  const [value, setValue] = useState('');
  const angularAddButton = React.createElement('angular-core-button', {}, 'Добавить')
  const angularAddButtonDisabled = React.createElement('angular-core-button', { disabled: true }, 'Добавить')

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const embed = () => {
    if (value.length === 0) return;

    const providerType = getProvider(value);
    // @ts-ignore
    const videoId = providerType ? ProviderGetters[providerType]?.(value) : null;

    if (!providerType || !videoId) return console.warn('Unsopperted video provider or video id is not found.');

    Elements.updateElement<VideoPluginElement, VideoElementProps>(editor, blockId, {
      type: 'video',
      props: {
        src: value,
        provider: { type: providerType, id: videoId, url: value },
      },
    });

    onClose();
  };

  const isEmpty = value.length === 0;

  return (
    <Portal id="uploader-portal">
      <Overlay lockScroll className="z-[100]" onClick={onClose}>
        <div
          ref={refs.setFloating} style={{ ...floatingStyles, boxShadow: "0px 1px 4px 0px #00000040" }}
          onClick={(e) => e.stopPropagation()}
          className={'yoopta-link-preview'}
        >
          <input
            type="text"
            placeholder="Вставьте ссылку на видео"
            value={value}
            autoFocus={true}
            className="yoopta-link-tool-input w-[300px]"
            onChange={onChange}
          />
          <button
            type="button"
            disabled={isEmpty}
            onClick={embed}
          >
            {!isEmpty && angularAddButton}
            {isEmpty && angularAddButtonDisabled}
          </button>
        </div>
      </Overlay>
    </Portal>
  );
};

export { VideoUploader };
