import { Executor, Reducer } from "src/app/core/mvi/store";
import { AuthorizationState } from "./authorization-state";
import { AuthorizationAction, AuthorizationActionTypes } from "./authorization-action";
import { AuthorizationResultAction, AuthorizationResultActionTypes } from "./authorization-result-action";
import { Inject, Injectable } from "@angular/core";
import { Validator } from "../../../../core/validators/validator";
import { AuthService } from "../../domain/auth.service";
import { AuthorizationNavigator } from "../navigation/authorization-navigator";
import { LoginByPasswordData } from "../../domain/login-by-password-data";
import { LoginStatus } from "../../domain/login-status";
import { ToastsService } from "../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class AuthorizationExecutor extends Executor<
  AuthorizationState,
  AuthorizationAction,
  AuthorizationResultAction
> {
  constructor(
    @Inject('EmailValidator') private emailValidator: Validator,
    @Inject('PasswordValidator') private passwordValidator: Validator,
    private authService: AuthService,
    private navigator: AuthorizationNavigator,
    private toastsService: ToastsService
  ) {
    super();
  }

  override init(
    reducer: Reducer<AuthorizationState, AuthorizationResultAction>,
    getState: () => AuthorizationState,
    onReduced: (state: AuthorizationState) => void)
  {
    super.init(reducer, getState, onReduced);
    const rememberMeEmail = localStorage.getItem('rememberMe')
    if(rememberMeEmail){
      this.execute({
        type: AuthorizationActionTypes.CHANGE_EMAIL,
        email: rememberMeEmail
      })
    }
  }

  execute(action: AuthorizationAction) {
    switch (action.type) {
      case AuthorizationActionTypes.TOGGLE_REMEMBER_ME:
        this.reduce({
          type: AuthorizationResultActionTypes.TOGGLE_REMEMBER_ME,
        });
        break;

      case AuthorizationActionTypes.CHANGE_EMAIL:
        this.reduce({
          type: AuthorizationResultActionTypes.CHANGE_EMAIL,
          email: action.email,
        });
        break;

      case AuthorizationActionTypes.CHANGE_PASSWORD:
        this.reduce({
          type: AuthorizationResultActionTypes.CHANGE_PASSWORD,
          password: action.password,
        });
        break;

      case AuthorizationActionTypes.LOGIN:
        this.handleLogin();
        break;
    }
  }

  private handleLogin() {
    this.reduce({ type: AuthorizationResultActionTypes.CHANGE_LOADING_STATE });

    let emailError = this.emailValidator.validate(this.getState().email);
    let passwordError = this.passwordValidator.validate(
      this.getState().password,
    );

    if (emailError != null || passwordError != null) {
      this.reduce({
        type: AuthorizationResultActionTypes.VALIDATION_ERROR,
        emailError: emailError != null ? emailError : '',
        passwordError: passwordError != null ? passwordError : '',
      });
      return;
    }

    this.authService
      .login(
        new LoginByPasswordData(
          this.getState().email,
          this.getState().password,
        ),
      ).subscribe((status) => {
        this.handleStatus(status);
      });
  }

  private handleStatus(status: LoginStatus) {
    switch (status) {
      case LoginStatus.SUCCESS:
        if(this.getState().rememberMe){
          localStorage.setItem('rememberMe', this.getState().email)
        }
        this.navigator.openMainPage();
        break;
      case LoginStatus.INCORRECT_CREDENTIALS:
        this.toastsService.createToast({
          title: 'Пользватель не найден',
          description: 'Данная комбинация e-mail и пароля не известна',
          state: ToastState.ERROR
        })
        this.reduce({
          type: AuthorizationResultActionTypes.CHANGE_LOADING_STATE
        });
        break;
      case LoginStatus.UNKNOWN:
        this.toastsService.createToast({
          title: 'Неизвестная ошибка',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: AuthorizationResultActionTypes.CHANGE_LOADING_STATE
        });
        break;
    }
  }
}
