import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './core/components/components.module';
import { AuthorizationModule } from './features/authorization/authorization.module';
import { AuthServiceImpl } from './features/authorization/data/auth-service-impl.service';
import { AuthInterceptor } from './features/authorization/data/interceptors/auth-interceptor';
import { AuthService } from './features/authorization/domain/auth.service';
import { SectionContentModule } from './features/documentation/section-contents/impl/section-content.module';
import { SectionSettingsModule } from './features/documentation/section-settings/impl/section-settings.module';
import { SectionModule } from './features/documentation/section/impl/section.module';
import { SectionsModule } from './features/documentation/sections/impl/sections.module';
import { EmployeesModule } from './features/employees/employees.module';
import { AssessmentSessionsModule } from './features/grade/assessment-sessions/assessment-sessions.module';
import { CompetenciesAndSkillsModule } from './features/grade/competencies-and-skills/competencies-and-skills.module';
import { CompetencyProfilesModule } from './features/grade/competency-profiles/competency-profiles.module';
import { GradeHomeModule } from './features/grade/grade-home/grade-home.module';
import { GradeLogsModule } from './features/grade/grade-logs/grade-logs.module';
import { GradeSettingsModule } from './features/grade/grade-settings/grade-settings.module';
import { GradeModule } from './features/grade/grade/grade.module';
import { RatingScalesModule } from './features/grade/rating-scales/rating-scales.module';
import { MainModule } from './features/main/main.module';
import { MessageListModule } from './features/messenger/messages/message-list.module';
import { MessengerModule } from './features/messenger/messenger.module';
import { NewPasswordModule } from './features/new-password/new-password.module';
import { ProfileModule } from './features/profile/profile.module';
import { RegistrationModule } from './features/registration/registration.module';
import { ResetPasswordModule } from './features/reset-password/reset-password.module';
import { DocumentModule } from "./features/document/impl/document.module";
import { SectionEditorModule } from "./features/documentation/section-editor/section-editor.module";
import { KnowledgeBaseModule } from './features/knowledge-base/knowledge-base.module';
import { SettingsModule } from './features/settings/settings.module';
import { RefreshTokenInterceptor } from './features/authorization/data/interceptors/refresh-token-interceptor';
import { NewsModule } from "./features/news/news.module";
import { AgilesModule } from "./features/agiles/agiles.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthorizationModule,
    EmployeesModule,
    MainModule,
    SectionsModule,
    SectionSettingsModule,
    SectionContentModule,
    SectionEditorModule,
    ComponentsModule,
    RegistrationModule,
    ProfileModule,
    SectionModule,
    ResetPasswordModule,
    NewPasswordModule,
    MessengerModule,
    MessageListModule,
    GradeModule,
    GradeHomeModule,
    GradeSettingsModule,
    GradeLogsModule,
    RatingScalesModule,
    CompetenciesAndSkillsModule,
    CompetencyProfilesModule,
    AssessmentSessionsModule,
    DocumentModule,
    KnowledgeBaseModule,
    SettingsModule,
    NewsModule,
    AgilesModule
  ],
  providers: [
    {
      provide: AuthService,
      useClass: AuthServiceImpl,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
