import { ListType } from "../../../news-list/domain/list-type";
import { YooptaEditorComponent } from "../../../../../../core/components/yoopta-editor/yoopta-editor.component";
import { QueryList } from "@angular/core";
import { InputFieldComponent } from "../../../../../../core/components/fields/input-field/input-field.component";
import {
  TextareaFieldComponent
} from "../../../../../../core/components/fields/textarea-field/textarea-field.component";

export type NewsEditorAction =
  | BackAction
  | ChangeTitleAction
  | ChangeDescriptionAction
  | ChangeImageIdAction
  | PublishAction
  | ChangePublishSidebarVisibleAction
  | ChangePreviewTypeAction
  | CloseBackModalAction
  | SaveAndMoveAction
  | CloseAfkModalAction
  | BackClickedAction
  | CloseSessionAction
  | InitChildrenAction
  | PageUnloadAction
  | ChangeIsEditorLoadingAction

export enum NewsEditorActionTypes {
  BACK,
  BACK_CLICKED,
  CHANGE_PREVIEW_TYPE,
  CHANGE_PUBLISH_SIDEBAR_VISIBLE,
  CHANGE_TITLE,
  CHANGE_DESCRIPTION,
  CHANGE_IMAGE_ID,
  PUBLISH,
  SAVE_AND_MOVE,
  CLOSE_BACK_MODAL,
  CLOSE_AFK_MODAL,
  CLOSE_SESSION,
  INIT_CHILDREN,
  PAGE_UNLOAD,
  CHANGE_IS_EDITOR_LOADING,
}

export interface ChangeIsEditorLoadingAction{
  readonly type: NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING;
  readonly value: boolean
}

export interface InitChildrenAction{
  readonly type: NewsEditorActionTypes.INIT_CHILDREN;
  readonly editor?: YooptaEditorComponent
  readonly inputEls?: QueryList<InputFieldComponent | TextareaFieldComponent>
}

export interface PageUnloadAction {
  readonly type: NewsEditorActionTypes.PAGE_UNLOAD;
}

export interface CloseSessionAction {
  readonly type: NewsEditorActionTypes.CLOSE_SESSION;
}

export interface BackClickedAction {
  readonly type: NewsEditorActionTypes.BACK_CLICKED,
}

export interface CloseAfkModalAction {
  readonly type: NewsEditorActionTypes.CLOSE_AFK_MODAL,
}

export interface SaveAndMoveAction {
  readonly type: NewsEditorActionTypes.SAVE_AND_MOVE,
}

export interface CloseBackModalAction {
  readonly type: NewsEditorActionTypes.CLOSE_BACK_MODAL,
}

export interface ChangePreviewTypeAction {
  readonly type: NewsEditorActionTypes.CHANGE_PREVIEW_TYPE
  readonly value: ListType
}

export interface ChangePublishSidebarVisibleAction {
  readonly type: NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE
  readonly value: boolean
}

export interface PublishAction {
  readonly type: NewsEditorActionTypes.PUBLISH,
}

export interface BackAction {
  readonly type: NewsEditorActionTypes.BACK,
}

export interface ChangeTitleAction {
  readonly type: NewsEditorActionTypes.CHANGE_TITLE,
  readonly value: string
}

export interface ChangeDescriptionAction {
  readonly type: NewsEditorActionTypes.CHANGE_DESCRIPTION,
  readonly value: string
}

export interface ChangeImageIdAction {
  readonly type: NewsEditorActionTypes.CHANGE_IMAGE_ID,
  readonly value: string
}
