export type OrdersAction =
  | ChangeCancelModalVisibleAction

export enum OrdersActionTypes {
  CHANGE_CANCEL_MODAL_VISIBLE,
}

export interface ChangeCancelModalVisibleAction {
  readonly type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE
  readonly value: boolean
}
