import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output, Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';

const TOGGLE_ITEM_START_ID = 'toggle-item-'

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
})
export class TogglerComponent implements AfterViewInit, OnChanges{
  @Input() selectedId: string = ''
  @Input() toggleItems: ToggleItem[] = []

  @Output() toggle = new EventEmitter<string>();

  @ViewChild('selectedBg') selectedBg!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef)
  {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(entry => {
        this.selectionChange()
      })
    })
    resizeObserver.observe(el.nativeElement);
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.selectionChange()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedId']){
      this.selectionChange()
    }
  }

  selectionChange(){
    const selectedEl = document.getElementById(TOGGLE_ITEM_START_ID + this.selectedId);
    if(selectedEl){
      this.renderer.setStyle(this.selectedBg.nativeElement,'transform', `translateX(${selectedEl.offsetLeft - 4}px) translateY(${selectedEl.offsetTop - 4}px)`)
      this.renderer.setStyle(this.selectedBg.nativeElement,'width', `${selectedEl.offsetWidth}px`)
      this.renderer.setStyle(this.selectedBg.nativeElement,'height', `${selectedEl.offsetHeight}px`)
    }
  }

  onToggle(id: string){
    this.toggle.emit(id);
  }

  protected readonly TOGGLE_ITEM_ID = TOGGLE_ITEM_START_ID;
}

export type ToggleItem = {
  id: string,
  text: string,
  suffix?: string
}
