import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NewsComponent } from './presentation/news.component';
import { ComponentsModule } from "../../core/components/components.module";
import { RouterOutlet } from "@angular/router";
import { NewsListModule } from "./modules/news-list/news-list.module";
import { NewsService } from "./data/news-service";
import { NewsEditorModule } from "./modules/news-editor/news-editor.module";

@NgModule({
  declarations: [
    NewsComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NewsListModule,
    NewsEditorModule,
    RouterOutlet,
  ],
  providers: [
    NewsService
  ]
})
export class NewsModule { }
