import { Reducer } from "src/app/core/mvi/store";
import { CreateEmployeeModalState, UserForm } from "./create-employee-modal-state";
import {
  CreateEmployeeModalResultAction,
  CreateEmployeeModalResultActionTypes
} from "./create-employee-modal-result-action";
import { Injectable } from "@angular/core";
import { stringToRgb } from "../../../../../../../../core/utils/string-to-rgb";
import { EmployeeRole } from "../components/create-employee-modal-roles/create-employee-modal-roles.component";
import { EmployeeRoleDto } from "../../../../../../data/dto/employee-dto";

@Injectable()
export class CreateEmployeeModalReducer
  implements Reducer<CreateEmployeeModalState, CreateEmployeeModalResultAction>
{
  reduce(
    state: CreateEmployeeModalState,
    action: CreateEmployeeModalResultAction,
  ): CreateEmployeeModalState {
    switch (action.type) {
      case CreateEmployeeModalResultActionTypes.INIT_VIEW:
        return {...state, inputs: action.inputs}
      case CreateEmployeeModalResultActionTypes.INIT:
        return {...state,
          loadedEmployeeId: -1,
          canDelete: false,
          canEdit: true,
          loadedEmployeeName: ''
        }
      case CreateEmployeeModalResultActionTypes.LOAD_EMPLOYEE:
        return {...state,
          userForm: action.userData,
          loadedEmployeeId: action.employeeId,
          topBgColorStr: action.userData.email.value,
          roles: this.sortRoles(action.roles),
          canDelete: action.canDelete,
          canEdit: action.canEdit,
          avatarPlaceholder: this.getAvatarPlaceHolder(action.userData),
          avatarColorRGB: stringToRgb(action.userData.email.value),
          initialForm: {...action.userData},
          isEqualInit: true,
          loadedEmployeeName: action.userData.surname.value + ' ' + action.userData.name.value + ' ' + action.userData.patronymic.value,
          isValidForm: action.isValid,
          canEditRole: action.canEditRole
        }
      case CreateEmployeeModalResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value, loadedEmployeeId: action.employeeId ? action.employeeId : state.loadedEmployeeId};

      case CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA:
        return {...state,
          userForm: action.newData,
          avatarPlaceholder: this.getAvatarPlaceHolder(action.newData),
          isValidForm: action.isValid,
          isEqualInit: this.isUserFormEquals(state.initialForm, action.newData)
        }
      case CreateEmployeeModalResultActionTypes.CHANGE_ROLES:
        const newUserForm = {...state.userForm,
          roleIds: action.roles.map((role) => role.id)
        }
        return {...state,
          roles: this.sortRoles(action.roles),
          userForm: newUserForm,
          isEqualInit: this.isUserFormEquals(state.initialForm, newUserForm)
        }
      case CreateEmployeeModalResultActionTypes.CHANGE_AVATAR_COLOR:
        return {...state, avatarColorRGB: action.colorRGB}
      case CreateEmployeeModalResultActionTypes.CHANGE_IS_SENDING_REQUEST:
        return {...state, isSendingRequest: action.value}
    }
  }

  private sortRoles(roles: EmployeeRoleDto[]): EmployeeRoleDto[] {
    return roles.sort(
      (a, b) =>{
        return !a.isEditable ? (!b.isEditable ? 0 : -1) : 1
      }
    )
  }

  private getAvatarPlaceHolder(userForm: UserForm): string{
    const surnameLetter = userForm.surname.value.trimStart().at(0)
    const nameLetter = userForm.name.value.trimStart().at(0)
    return (surnameLetter ? surnameLetter: '') + (nameLetter ? nameLetter: '')
  }

  private isUserFormEquals(a: UserForm, b: UserForm): boolean{
    let aRoles = ''
    let bRoles = ''
    a.roleIds.forEach((roleId) => {
      aRoles = aRoles + roleId
    })
    b.roleIds.forEach((roleId) => {
      bRoles = bRoles + roleId
    })

    return a.name.value === b.name.value
      && a.surname.value === b.surname.value
      && a.patronymic.value === b.patronymic.value
      && a.phoneNumber.value.replace(/\D/g, '') === b.phoneNumber.value.replace(/\D/g, '')
      && a.email.value === b.email.value
      && aRoles === bRoles
  }
}
