<app-core-modal
  [open]="!state.isInvoicePaymentModalVisible"
  [paddingType]="'none'"
  [className]="'w-full flex flex-row relative max-h-[644px] h-full max-w-[1064px]'"
  (onClose)="close.emit()"
>
  <div class="max-w-[362px] w-full flex flex-col gap-[16px] h-full p-[32px] clue-bg rounded-l-[16px]"
  >
    <p class="font-semibold text-[22px] leading-[27px] text-tt-text-default">
      Заполните форму для выставления счета
    </p>
    <p class="text-[15px] leading-[18px] text-tt-text-default">
      Вы получите
    </p>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/invoicing-modal/rocket.png" height="56" width="56" alt="rocket" draggable="false">
      <p class="text-[15px] leading-[18px]">Доступ для {{state.countUsers}} {{ usersStr }}</p>
    </div>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/invoicing-modal/cd.png" height="56" width="56" alt="cd" draggable="false">
      <p class="text-[15px] leading-[18px]">{{state.countUsers / 2}} Гб дискового пространства</p>
    </div>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/invoicing-modal/watch.png" height="56" width="56" alt="watch" draggable="false">
      <p class="text-[15px] leading-[18px]">Срок действия 1 {{state.periodType == 'year' ? 'год' : 'месяц'}} с момента оплаты</p>
    </div>
  </div>

  <app-core-modal-content
    class="flex-grow p-[24px] pr-[20px]"
    [parentPaddings]="true"
    [footer]="true"
    overflowY="scroll"
    contentHeight="full"
  >
    <div class="flex flex-col justify-between h-full pb-[16px]">
      <div class="flex flex-col gap-[12px]">
        <app-core-input-field
          variant="borderless"
          placeholder="Название компании"
          [textStyle]="'text-[16px] leading-[19px]'"
          errorShowType="onUnFocused"
          [error]="state.organisationName.error"
          [autoFocus]="true"
          [defaultValue]="state.organisationName.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_ORGANISATION_NAME,
            value: $event
          })"
        />

        <app-core-input-field
          variant="borderless"
          placeholder="Юридический адрес"
          [textStyle]="'text-[16px] leading-[19px]'"
          errorShowType="onUnFocused"
          [error]="state.legalAddress.error"
          [defaultValue]="state.legalAddress.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_LEGAL_ADDRESS,
            value: $event
          })"
        />

        <div class="flex h-[40px] items-center gap-[8px]">
          <app-core-checkbox-field
            [value]="state.isISoloProprietor"
            [label]="'Я индивидуальный предприниматель'"
            (onToggle)="performAction({
              type: InvoicingModalActionTypes.TOGGLE_I_SOLE_PROPRIETOR
            })"
          />
        </div>

        <div class="flex w-full gap-[16px]">
          <app-core-input-field
            class="w-full"
            variant="borderless"
            [charsCounterMax]="state.isISoloProprietor ? 12 : 10"
            placeholder="ИНН"
            [mask]="'000000000000'"
            [textStyle]="'text-[16px] leading-[19px]'"
            errorShowType="onUnFocused"
            [error]="state.INN.error"
            [defaultValue]="state.INN.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_INN,
              value: $event
            })"
          />
          <app-core-input-field
            *ngIf="!state.isISoloProprietor"
            class="w-full"
            variant="borderless"
            [charsCounterMax]="9"
            placeholder="КПП"
            mask="000000000"
            [textStyle]="'text-[16px] leading-[19px]'"
            errorShowType="onUnFocused"
            [error]="state.KPP.error"
            [defaultValue]="state.KPP.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_KPP,
              value: $event
            })"
          />
        </div>

        <app-core-input-field
          variant="borderless"
          placeholder="Телефон для связи"
          [textStyle]="'text-[16px] leading-[19px]'"
          errorShowType="onUnFocused"
          prefix="+"
          mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
          [error]="state.phoneNumber.error"
          [defaultValue]="state.phoneNumber.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_PHONE_NUMBER,
            value: $event
          })"
        />
      </div>
      <p class="text-[14px] leading-[18px] text-tt-text-secondary">
        Нажимая «<span class="text-tt-primary cursor-pointer">Получить счет</span>», вы соглашаетесь с условиями
        <span class="text-tt-primary cursor-pointer">лицензионного соглашения</span>
        и подтверждаете ознакомление с
        <span class="text-tt-primary cursor-pointer">политикой в отношении обработки и защиты персональных данных.</span>
      </p>
    </div>

    <app-core-modal-content-footer
      widthType="full"
    >
        <app-core-button
          size="l"
          class="w-full"
          className="w-full"
          (onClick)="performAction({
            type: InvoicingModalActionTypes.OPEN_INVOICE_PAYMENT_MODAL
          })"
        >
          <span
          >Получить счет</span>
        </app-core-button>
    </app-core-modal-content-footer>

  </app-core-modal-content>

</app-core-modal>

<app-core-alert-modal
  *ngIf="state.isInvoicePaymentModalVisible"
  [title]="'Счет на оплату заказа'"
  type="info"
  [description]="'Оплатите заказ до 07.08.2024. Если заказ не будет оплачен, мы автоматически отменим его.'"
  [confirmButtonText]="'Закрыть'"
  (onConfirmClicked)="close.emit()"
  (onCancelClicked)="close.emit()"
>
  <p>Для оплаты заказа, оплатите сгенерированный счет, который вы можете просмотреть ниже. При поступлении оплаты, мы обновим тариф для вашего аккаунта в соответствии с выбранными опциями.</p>

  <div class="mt-[16px] border border-tt-border-default px-[16px] py-[11px] rounded-[8px] flex gap-[12px] items-center">
    <svg-file class="text-tt-text-secondary w-[24px] h-[24px]"/>
    <div>
      <p class="text-[14px] text-tt-text-default">Счет-оферта_TeamTells.pdf</p>
      <p class="text-[13px] text-tt-text-secondary">14.3Mb - <button class="text-tt-text-link">Загрузить</button></p>
    </div>
  </div>

</app-core-alert-modal>
