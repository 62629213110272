import { SubscriptionFormState } from "./subscription-form-state";
import { SubscriptionFormAction, SubscriptionFormActionTypes } from "./subscription-form-action";
import { SubscriptionFormResultAction, SubscriptionFormResultActionTypes } from "./subscription-form-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { DropdownItem } from "../../../../../../../../core/components/fields/dropdown-field/dropdown-field.component";
import { ReceiptService } from "../../../../data/receipt-service";
import { convertDurationToDropDownItem } from "../../../utils/convert-tariff-duration";
import { ReceiptField } from "../../../../domain/receipt-secton";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ruNumWord } from "../../../../../../../../core/utils/ru-num-word";
import { SettingsConstants } from "../../../../../../common/settings-constants";
import { Location } from "@angular/common";
import { SubscriptionService } from "../../../../../../data/subscription.service";

@Injectable()
export class SubscriptionFormExecutor extends Executor<
  SubscriptionFormState,
  SubscriptionFormAction,
  SubscriptionFormResultAction
> {

  constructor(
    private receiptService: ReceiptService,
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
    private location: Location,
  ) {
    super();
  }

  override init(
    reducer: Reducer<SubscriptionFormState, SubscriptionFormResultAction>,
    getState: () => SubscriptionFormState,
    onReduced: (state: SubscriptionFormState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: SubscriptionFormAction) {
    switch (action.type) {
      case SubscriptionFormActionTypes.CHANGE_TARIFF:
        const findTariff = this.getState().tariffDropdownItems.find(tariff => tariff.id === action.tariffId)
        if(findTariff) {
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_TARIFF,
            tariff: findTariff
          })
        }
        break
      case SubscriptionFormActionTypes.CHANGE_DURATION:
        const findDuration = this.getState().durationDropdownItems.find(duration => duration.id === action.durationId)
        if(findDuration) {
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_DURATION,
            duration: findDuration
          })
        }
        break
      case SubscriptionFormActionTypes.CHANGE_ADDITIONAL_MEMORY:
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY,
          value: action.value
        })
        break
      case SubscriptionFormActionTypes.CHANGE_USERS_COUNT:
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_USERS_COUNT,
          value: action.value
        })
        break
    }
    this.updateReceipt()
  }

  private updateReceipt(){
    const state = this.getState()
    const currentDuration = state.currentDuration
    const additionalTariff = state.additionalTariff
    if(currentDuration && additionalTariff)
    {
      const findDuration = state.durations.find(dur => dur.duration === parseInt(currentDuration.id))
      const findTariff = state.allTariffs.find((tariff)=> tariff.id == state.currentTariff?.id)

      if(findDuration && findTariff){
        let fields: ReceiptField[] = []
        const price = findTariff.costs.find((cost) => cost.duration === findDuration.duration)
        if(price && state.usersCount > 0)
        {
          fields.push({
            text: `Доступ к тарифу «${
              findTariff.name}» для ${
              state.usersCount} ${
                ruNumWord(
                  state.usersCount,
                  SettingsConstants.USER_STRINGS
                )
              } ${currentDuration.name}`,
            price: price.value * state.usersCount * findDuration.duration
          })
          if(findDuration.discount){
            fields.push({
              text: `Скидка ${findDuration.discount}% за продление тарифа ${currentDuration.name}`,
              price: -Math.round(price.value * state.usersCount * findDuration.duration * findDuration.discount / 100)
            })
          }
        }
        if(state.memoryCount > 0){
          const memoryCost = additionalTariff.storageCosts.find(
            (cost) => cost.minCount <= state.memoryCount &&  state.memoryCount <= cost.maxCount
          )
          if(memoryCost){
            fields.push({
              text: `Дополнительные ГБ на диске ${currentDuration.name}`,
              price: memoryCost.value * findDuration.duration * state.memoryCount
            })
          }
        }

        this.receiptService.updateSections([{fields: fields}])
      }
    }
  }

  private handleInit(){
    this.reduce({
      type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })

    this.subscriptionService.getCreateInvoiceData().subscribe({
      next: (dto) => {
        let currentDuration: DropdownItem | null = null;
        const durations = dto.durations.map<DropdownItem>(
          (duration, i) => {
            const result = convertDurationToDropDownItem(duration)
            if (duration.duration === dto.baseDuration){
              currentDuration = result
            }
            return result
          }
        )

        let currentTariff: DropdownItem | null = null;
        const tariffs = dto.allTariffs.map<DropdownItem>(
          (tariff, i) => {
            const currentCost = tariff.costs.find((cost)=> cost.duration === dto.baseDuration)
            const result: DropdownItem = {
              id: tariff.id,
              name: `«${tariff.name}» ${currentCost?.value} ₽`
            }
            if (!currentTariff){
              currentTariff = result
            }
            return result
          }
        )

        setTimeout(() => {
          this.reduce({
            type: SubscriptionFormResultActionTypes.INIT,
            durationDropdownItems: durations,
            currentDuration: currentDuration,
            durations: dto.durations,
            tariffDropdownItems: tariffs,
            currentTariff: currentTariff,
            allTariffs: dto.allTariffs,
            additionalTariff: dto.additionalTariff,
          })

          setTimeout(() => this.updateReceipt())
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
            value: false
          })
        }, 500)
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке данных для продления подписки',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
        this.location.back()
      }
    })
  }
}
