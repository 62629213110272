<div
  *ngIf="toggleItems.length > 0"
  class="price-toggle"
>
  <div *ngIf="selectedId != ''" #selectedBg class="absolute price-toggle-button bg-[#F0F5FC] transition-transform"></div>

  <button
    *ngFor="let toggleItem of toggleItems"
    class="price-toggle-button z-[1]"
    (click)="onToggle(toggleItem.id)"
    [ngClass]="{
      'text-tt-text-main': toggleItem.id === selectedId
    }"
    [id]="TOGGLE_ITEM_ID + toggleItem.id"
  >
    {{toggleItem.text}}<span class="text-tt-primary">{{toggleItem.suffix}}</span>
  </button>
</div>
