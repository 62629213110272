import { DropdownItem } from "../../../../../../../../core/components/fields/dropdown-field/dropdown-field.component";
import { DurationDto, TariffDto } from "../../../../../../data/dto/all-tariffs-dto";
import { AdditionalTariff } from "../../../../../../data/dto/get-update-invoice-data-dto";

export type SubscriptionFormResultAction =
  | InitResultAction
  | ChangeAdditionalMemoryResultAction
  | ChangeUsersCountResultAction
  | ChangeTariffResultAction
  | ChangeDurationResultAction
  | ChangeIsLoadingResultAction

export enum SubscriptionFormResultActionTypes {
  INIT,
  CHANGE_TARIFF,
  CHANGE_DURATION,
  CHANGE_USERS_COUNT,
  CHANGE_ADDITIONAL_MEMORY,
  CHANGE_IS_LOADING
}

export interface ChangeIsLoadingResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeAdditionalMemoryResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY
  readonly value: number
}

export interface ChangeUsersCountResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_USERS_COUNT
  readonly value: number
}

export interface ChangeTariffResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_TARIFF
  readonly tariff: DropdownItem
}

export interface ChangeDurationResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_DURATION
  readonly duration: DropdownItem
}

export interface InitResultAction {
  readonly type: SubscriptionFormResultActionTypes.INIT
  readonly durationDropdownItems: DropdownItem[]
  readonly currentDuration: DropdownItem | null
  readonly durations: DurationDto[]

  readonly tariffDropdownItems: DropdownItem[]
  readonly currentTariff: DropdownItem | null
  readonly allTariffs: TariffDto[]

  readonly additionalTariff: AdditionalTariff | null
}
