<div class="child-routes-size tiny-scrollbar"
     (scroll)="onScroll()"
     #scrollable
>
  <div
    class="px-[24px]"
  >
    <app-core-page-header

      [title]="'@project_name'"
      [navTabs]="navTabs"
      navTabType="link"
      textSize="5xl"
      [borderBottom]="false"
    />
  </div>


  <router-outlet></router-outlet>

  <app-tasks-table
    [table]="table"
  />
</div>
