import { Injectable } from '@angular/core';
import { DropdownItem } from "../../../../../../core/components/fields/dropdown-field/dropdown-field.component";
import { SettingsMainConstants } from "../../common/settings-main-constants";

@Injectable()
export class SettingsMainState {
  readonly organisationId: string = ''

  readonly organisationTitle: string = ''
  readonly newOrganisationTitle: string = ''
  readonly organisationTitleError: string | null = null
  readonly currentNavItem: SettingsMainNavItems = SettingsMainNavItems.MAIN

  readonly isDeleteAlertVisible: boolean = false

  readonly isPageLoading: boolean = false

  readonly languageItems: DropdownItem[] = SettingsMainConstants.LANGUAGE_DROPDOWNS;
  readonly selectedLanguage: DropdownItem = SettingsMainConstants.LANGUAGE_DROPDOWNS[0];
}

export enum SettingsMainNavItems {MAIN}
