<div
  *ngIf="showType === 'base'"
  class="bg-white rounded-[8px] overflow-hidden border border-tt-border-default flex gap-[4px]"
  [ngClass]="{
    'border-[2px] border-tt-primary': isSelected
  }"
  (click)="onClick($event)"
>
  <div
    class="w-[3px] shrink-0"
    [ngClass]="{
      'bg-tt-icon-success': task.priority === TaskPriority.NORMAL,
      'critical-bg': task.priority === TaskPriority.CRITICAL,
    }"
  ></div>
  <div class="py-[8px] pr-[8px] flex flex-col gap-[8px]">
    <p class="text-tt-font-size_l text-tt-text-default">
      <span class="text-tt-text-secondary">
        {{ taskTagNumber }}&nbsp;&nbsp;&nbsp;</span>{{task.title}}
    </p>

    <p class="text-tt-font-size_s text-tt-text-secondary line-clamp-3">
      {{task.description}}
    </p>

    <div
      class="flex gap-[8px] items-center text-tt-text-secondary text-tt-font-size_s"
    >
      <app-task-avatar
        [executor]="executor"
      />

      <div
        class="flex gap-[4px] items-center "
      >
        <div
          class="size-[8px] rounded-[4px] shrink-0"
          [ngClass]="{
            'bg-tt-icon-success': task.priority === TaskPriority.NORMAL,
            'critical-bg': task.priority === TaskPriority.CRITICAL,
          }"
        ></div>
        <p>{{ task.priority === TaskPriority.NORMAL ? 'Обычная' : 'Критикал'}}</p>
      </div>

      <p>Задача</p>
    </div>
  </div>
</div>

<div
  *ngIf="showType === 'hide'"
  class="inline-block m-[2px] size-[8px]"
  [ngClass]="{
    'bg-tt-icon-success': task.priority === TaskPriority.NORMAL,
    'critical-bg': task.priority === TaskPriority.CRITICAL,
  }"
  [matTooltip]="task.title"
></div>
