import { Injectable } from "@angular/core";
import { OrderEntity } from "../../domain/order-entity";

@Injectable()
export class OrdersState {
  readonly activeOrder?: OrderEntity
  readonly storyOrders: OrderEntity[] = []

  readonly cancelModalVisibility: boolean = false;
}
