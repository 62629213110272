import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilesComponent } from "./presentation/agiles.component";
import { TaskItemComponent } from './presentation/components/task-item/task-item.component';
import { ComponentsModule } from "../../core/components/components.module";
import { RouterOutlet } from "@angular/router";
import { TaskAvatarComponent } from './presentation/components/task-avatar/task-avatar.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { TasksTableComponent } from './presentation/components/tasks-table/tasks-table.component';
import { SvgArrow, SvgPlus, SvgTrash } from "../../core/components/svg-components/svg.components";
import { CdkDrag, CdkDragPlaceholder, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    AgilesComponent,
    TaskItemComponent,
    TaskAvatarComponent,
    TasksTableComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterOutlet,
    MatTooltipModule,
    SvgArrow,
    SvgPlus,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    CdkDragPlaceholder,
    SvgTrash,
  ]
})
export class AgilesModule { }
