<div
  class="h-screen flex flex-col bg-white"
  *ngIf="!state.alreadyEditModalVisibility"
>
  <div class="h-[60px] w-screen pl-[16px] pr-[24px] fixed
    flex justify-between items-center z-50
    bg-tt-bg-page border-[1px] border-tt-border-default"
  >
    <div class="flex gap-[8px] items-center">
      <app-core-button-icon
        (click)="performAction({
          type: NewsEditorActionTypes.BACK_CLICKED,
        })"
      >
        <svg-document-back-arrow/>
      </app-core-button-icon>

      <p class="text-[14px] leading-[20px] text-tt-text-default">
        <span *ngIf="state.editorType === 'create'">Создание новости</span>
        <span *ngIf="state.editorType === 'edit'">Редакторование новости</span>
      </p>
    </div>

    <app-core-button
      (onClick)="performAction({
        type: NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE,
        value: true
      })"
    >
      Перейти к публикации
    </app-core-button>
  </div>

  <div
    class="h-[calc(100vh_-_60px)] mt-[60px] overflow-y-auto tiny-scrollbar"
    id="yoopta-editor-scroll"
  >
    <div
      class="w-full flex justify-center mt-[20px]"
      *ngIf="state.isEditorLoading"
    >
      <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
    </div>

    <app-yoopta-editor
      class="flex-grow-1 w-full flex justify-center pt-[40px]"
      [class.invisible]="state.isEditorLoading"
      [longreadId]="state.currentNews.longreadId"
      [title]="state.currentNews.title"
      (titleChange)="performAction({
        type: NewsEditorActionTypes.CHANGE_TITLE,
        value: $event
      })"
      (startLoading)="performAction({
        type: NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING,
        value: true
      })"
      (endLoading)="performAction({
        type: NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING,
        value: false
      })"
      #editor
    />
  </div>

</div>

<app-core-page-shading
  class="relative"
  [ngClass]="{
    'hidden': !state.isPublishSideBarOpen
  }"
>
  <div class="absolute right-0 max-w-[935px] w-full h-screen bg-tt-bg-page">
    <div class="h-[60px] w-full pl-[16px] pr-[24px] flex items-center justify-between border-tt-border-default border-[1px]">
      <div class="flex gap-[16px] items-center">
        <app-core-button-icon
          (onClick)="performAction({
            type: NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE,
            value: false
          })"
        >
          <svg-x-mark class="icon"/>
        </app-core-button-icon>

        <p class="text-tt-font-size_3xl text-tt-text-main">Настройки публикации</p>
      </div>

      <app-core-button
        (onClick)="performAction({
          type: NewsEditorActionTypes.PUBLISH
        })"
      >
        Опубликовать
      </app-core-button>
    </div>

    <div class="p-[24px] w-full flex flex-col gap-[24px] h-full">
      <div class="flex gap-[16px] w-full">
        <div class="flex flex-col gap-[16px] w-full">
          <app-core-input-field
            label="Заголовок"
            variant="standard"
            placeholder="Введите заголовок"
            errorShowType="onUnFocused"
            error="{{ state.titleError }}"
            [charsCounterMax]="128"
            [defaultValue]="state.currentNews.title"
            [value]="state.currentNews.title"
            [required]="true"
            (onChange)="performAction({
              type: NewsEditorActionTypes.CHANGE_TITLE,
              value: $event
            })"
            #publishInput
          />

          <app-core-textarea-field
            label="Описание"
            variant="standard"
            placeholder="Введите описание"
            errorShowType="onUnFocused"
            error="{{ state.descriptionError }}"
            [height]="92"
            [charsCounterMax]="180"
            [defaultValue]="state.currentNews.description"
            [value]="state.currentNews.description"
            [isResizable]="false"
            [autocomplete]=""
            [required]="true"
            (onChange)="performAction({
              type: NewsEditorActionTypes.CHANGE_DESCRIPTION,
              value: $event
            })"
            #publishInput
          />
        </div>

        <app-input-news-image
          (imageUpload)="performAction({
            type: NewsEditorActionTypes.CHANGE_IMAGE_ID,
            value: $event
          })"
        />
      </div>

      <div class="h-[40px] w-full flex justify-between items-center">
        <p class="text-[16px] font-medium leading-[30px] text-tt-text-secondary">
          Предпросмотр
        </p>

        <app-grid-list-toggle-button
          [currentType]="state.previewType"
          (toggleGrid)="performAction({
            type: NewsEditorActionTypes.CHANGE_PREVIEW_TYPE,
            value: 'grid-cell'
          })"
          (toggleList)="performAction({
            type: NewsEditorActionTypes.CHANGE_PREVIEW_TYPE,
            value: 'list-element'
          })"
        />
      </div>

      <app-news-item
        class="mt-[8px] w-full flex justify-center"
        [news]="state.currentNews"
        [type]="state.previewType"
      />
    </div>


  </div>
</app-core-page-shading>

<app-core-alert-modal
  *ngIf="state.afkModalVisibility"
  [title]="'Вы еще здесь?'"
  type="info"
  closeActionType="confirm"
  [isLoading]="state.isLoadingAfkModal"
  [hideContentOnLoad]="true"
  [cancelButtonText]="'Сохранить и выйти'"
  [confirmButtonText]="'Остаться'"
  (onCancelClicked)="performAction({
    type: NewsEditorActionTypes.SAVE_AND_MOVE
  })"
  (onConfirmClicked)="performAction({
    type: NewsEditorActionTypes.CLOSE_AFK_MODAL
  })"
>
  <app-core-stopwatch
    class="w-full flex justify-center"
    type="moveBack"
    [time]="300"
    [stopTime]="0"
  />
  <p class="mt-[8px]">После окончания времени новость автоматически будет сохранена и вы будете перенаправлены в режим просмотра.</p>
</app-core-alert-modal>

<div
  class="w-screen h-screen absolute left-0 top-0 bg-white z-50 flex items-center justify-center"
  *ngIf="state.isLoading"
>
  <mat-spinner
    class="size-[40px]"
  />
</div>

<app-core-alert-modal
  *ngIf="state.alreadyEditModalVisibility"
  [title]="'Новость уже редактируется'"
  type="info"
  closeActionType="confirm"
  [confirmButtonText]="'Закрыть'"
  (onConfirmClicked)="performAction({
    type: NewsEditorActionTypes.BACK
  })"
>
  <p>Новость сейчас редактируется другим пользователем. Вы сможете начать редактирование, как только предыдущий редактор закончит работу над новостью.</p>

</app-core-alert-modal>

<app-core-loading-shading
  *ngIf="state.isLoading"
  class="bg-white"
/>

<app-core-loading-shading
  *ngIf="state.isPageLoading"
/>
