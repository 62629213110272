<div
  class="py-[44px] max-w-[1256px] w-full mx-auto"
>
  <div class="flex flex-col gap-[16px]">
    <p class="block-title">Активный заказ</p>

    <p
      class="text-black text-tt-font-size_l"
      *ngIf="!state.activeOrder"
    >
      У вас нет активного заказа
    </p>

    <app-core-informer-text
      *ngIf="state.activeOrder"
      [informerText]="'Оплатите заказ до 07.10.2024. Если заказ не будет оплачен, мы автоматически отменим его.'"
    />

    <app-order
      *ngIf="state.activeOrder"
      [order]="state.activeOrder"
      [isOpen]="true"
      (onCancel)="performAction({
        type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE,
        value: true
      })"
    />
  </div>

  <div class="mt-[24px] flex flex-col gap-[16px]">
    <p class="block-title">История покупок</p>

    <p
      class="text-black text-tt-font-size_l"
      *ngIf="state.storyOrders.length === 0"
    >
      Вы еще не оформили ни одного заказа
    </p>

    <app-order
      *ngFor="let order of state.storyOrders"
      [order]="order"
    />
  </div>
</div>

<app-core-alert-modal
  *ngIf="state.cancelModalVisibility"
  [title]="'Отменить заказ'"
  type="warning"
  [cancelButtonText]="'Вернуться к заказам'"
  [confirmButtonText]="'Отменить заказ'"
  (onConfirmClicked)="performAction({
    type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE,
    value: false
  })"
  (onCancelClicked)="performAction({
    type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE,
    value: false
  })"
>
  Вы действительно хотите отменить заказ?
</app-core-alert-modal>
