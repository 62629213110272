<div
  class="dropdown-block"
>
  <div class="flex gap-[24px] items-center">
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Номер заказа
      </p>
      <p class="subscription-part-value">
        {{ order.id }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Способ оплаты
      </p>
      <p class="subscription-part-value">
        {{ order.paymentType === PaymentType.CARD ? 'Оплата онлайн' : 'Оплата по счету' }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Сумма к оплате
      </p>
      <p class="subscription-part-value">
        {{ getFormatNumberString(order.totalValue) }} ₽
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Статус
      </p>
      <p class="subscription-part-value">
        <span *ngIf="order.status === InvoiceStatus.CANCELED">Отменен</span>
        <span
          class="text-[#3BB46C]"
          *ngIf="order.status === InvoiceStatus.PAID"
        >Оплачен</span>
        <span
          class="text-tt-primary"
          *ngIf="order.status === InvoiceStatus.PENDING"
        >Ожидает оплаты</span>
      </p>
    </div>

    <button
      class="flex-grow-0 size-[36px] rounded-[8px] shrink-0 bg-[#F0F5FC] flex justify-center items-center"
      (click)="isOpen = !isOpen"
    >
      <svg-arrow class="size-[24px] block text-tt-text-secondary transition-transform"
        [class.rotate-90]="!isOpen"
        [class.rotate-[270deg]]="isOpen"
      />
    </button>
  </div>

  <div
    *ngIf="isOpen"
  >
    <app-receipt
      [sections]="order.receiptSections"
    />

    <div
      *ngIf="order.status === InvoiceStatus.PENDING"
      class="mt-[24px] w-full flex justify-end gap-[16px]"
    >
      <app-core-button
        variant="soft"
        (onClick)="onCancel.emit()"
      >
        Отменить заказ
      </app-core-button>
      <app-core-button>
        Перейти к оплате
      </app-core-button>
    </div>
  </div>
</div>
