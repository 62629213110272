import { Component } from "@angular/core";
import { AgilesConstants } from "./common/agiles-constants";
import { NavTab } from "../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../main/presentation/state/main-state";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { HeaderService } from "../../main/data/header-service";
import { TasksTableEntity } from "./components/tasks-table/tasks-table.component";
import { TaskPriority, TaskType } from "./components/task-item/task-item.component";

const task = {
    number: 1,
    projectTag: 'TTP',
    title: 'Надо делать дела',
    description: 'Недела делать не надо',
    priority: TaskPriority.NORMAL,
    type: TaskType.TASK
  }

const taskList = [
  {
    ...task
  },
  {
    ...task,
    number: 2,
    priority: TaskPriority.CRITICAL,
  },
]

@Component({
  selector: 'app-agiles',
  templateUrl: './agiles.component.html',
  styleUrls: [
    './agiles.component.scss',
    '../../main/presentation/main.component.scss'
  ]
})
export class AgilesComponent {

  navTabs: NavTab[]= [
    {
      id: Tabs.EMPLOYEES,
      url: MainRoutesPaths.AGILES,
      title: 'Главная',
    },
    {
      id: Tabs.ROLE,
      url: MainRoutesPaths.AGILES,
      title: 'Настройки',
    },
  ]

  onScroll(){

  }

  table: TasksTableEntity = {
    columns: [
      {
        name: 'Not started',
        isOpen: true,
      },
      {
        name: 'In progress',
        isOpen: true,
      },
      {
        name: 'Completed',
        isOpen: true,
      },
    ],
    rows: [
      {
        isOpen: true,
        executor: {
          id: 1,
          name: 'Дмитрий',
          surname: 'Воронин',
          email: 'vorondim@teamtells.com',
        },
        countTasks: 100500,
        columnsTasks: [taskList.slice(), taskList.slice(), taskList.slice()]
      },
      {
        isOpen: true,
        executor: {
          id: 1,
          name: 'Язаджи',
          surname: 'Алексей',
          email: 'yazalex@teamtells.com',
        },
        countTasks: 100500,
        columnsTasks: [taskList.slice(), taskList.slice(), taskList.slice()]
      }
    ],

  }

  protected readonly AgilesConstants = AgilesConstants;

  constructor(
    private headerService: HeaderService
  ){
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])

  }
}
