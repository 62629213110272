<div
  class="fixed w-screen h-screen left-0 top-0 bg-[#FAFBFF] z-50 py-[60px] overflow-y-auto"
  cdkTrapFocus
>
  <div
    *ngIf="isLoading"
    class="w-full h-full flex justify-center items-center"
  >
    <mat-spinner
      class="size-[48px]"
    />
  </div>
  <div
    *ngIf="!isLoading"
    class="w-full h-full flex flex-col items-center"
  >
    <a
      class="fixed top-[32px] right-[32px]"
      [routerLink]="'./'"
    >
      <app-core-button-icon
        size="xl"
        [autofocus]="true"
        [firstFocusHide]="true"
      >
        <svg-modal-xmark/>
      </app-core-button-icon>
    </a>

    <div
      class="max-w-[541px] w-full flex flex-col items-center"
    >
      <div class="flex gap-[14px]">
        <svg-team-tells-logo
          class="shrink-0 size-[44px]"
        />
        <p class="text-tt-font-size_7xl text-tt-text-main">TeamTells</p>
      </div>

      <div
        class="w-full mt-[55px]"
      >
        <ng-content/>
      </div>

      <app-payment
        class="mt-[16px]"
      />
    </div>
  </div>


</div>
