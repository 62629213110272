import { FilterMenuItem } from 'src/app/core/components/filter-menu/filter-menu.component';
import { ActionSection } from 'src/app/core/components/action-menu/action-menu.component';
import {
  SectionSummaryEntity,
  SectionPrivacy,
} from '../../domain/section-summary-entity';
import {
  AutocompleteItem
} from "../../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

export class Constants {
  public static NO_ID = -1;
  public static EMPTY_SECTION: SectionSummaryEntity = {
    id: 0,
    title: '',
    url: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
    isFavorite: false,
  };

  public static SORT_POPUP_SECTION: ActionSection[] = [
    {
      elements: [
        {
          id: 'alphabet',
          name: 'от А до Я',
        },
        {
          id: 'alphabet-revert',
          name: 'от Я до А',
        },
        {
          id: 'new-to-old',
          name: 'сначала новые',
        },
        {
          id: 'old-to-new',
          name: 'сначала старые',
        },
      ],
    },
  ];

  public static INIT_FILTER_MENU_ITEMS: FilterMenuItem[] = [
    {
      id: 0,
      title: 'Все разделы',
      isActive: true,
    },
    {
      id: 1,
      title: 'Отмеченные',
      isActive: false,
    },
    {
      id: 2,
      title: 'Мои',
      isActive: false,
    },
  ];

  public static PRIVACY_FIELD_VALUES: AutocompleteItem[] = [
    {
      id: SectionPrivacy.PUBLIC,
      name: 'Открытый',
      description: 'Доступен всем сотрудникам компании',
    },
    {
      id: SectionPrivacy.PRIVATE,
      name: 'Закрытый',
      description:
        'Недоступен большинству, доступ настраивается для каждого сотрудника/отдела отдельно, через настройки раздела.',
    },
  ];

  public static MOCK_ICON_URLS: string[] = [
    'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
    'assets/link-icon.svg',
    'assets/arrow.svg',
    'assets/doc-icon.svg',
  ];

  public static EMPTY_TITLE_ERROR = 'Название не должно быть пустым';
}
