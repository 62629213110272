import { Component } from "@angular/core";
import { SettingsMainExecutor } from "./state/settings-main-executor";
import { SettingsMainNavItems, SettingsMainState } from "./state/settings-main-state";
import { SettingsMainReducer } from "./state/settings-main-reducer";
import { SettingsMainAction, SettingsMainActionTypes } from "./state/settings-main-action";
import { Store } from "src/app/core/mvi/store";
import { SettingsMainResultAction } from "./state/settings-main-result-action";

@Component({
  selector: 'app-space-settings',
  templateUrl: './settings-main.component.html',
  providers: [
    SettingsMainExecutor,
    SettingsMainState,
    SettingsMainReducer
  ]
})
export class SettingsMainComponent extends Store<
  SettingsMainState,
  SettingsMainExecutor,
  SettingsMainAction,
  SettingsMainResultAction
>
{
  protected readonly SettingsMainNavItems = SettingsMainNavItems
  protected readonly SettingsMainActionTypes = SettingsMainActionTypes

  constructor(
    state: SettingsMainState,
    executor: SettingsMainExecutor,
    reducer: SettingsMainReducer,
  ) {
    super(state, executor, reducer);
  }
}
