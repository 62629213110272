<div
  class="flex flex-col gap-[16px]"
  [ngClass]="{
    'invisible w-[0px] h-[0px] overflow-hidden': state.receiptPrice == 0
  }"
>
  <div
    class="form-block"
  >
    <div
      class="flex flex-col gap-[24px]"
    >
      <div class="w-full flex justify-center">
        <app-toggler
          [selectedId]="state.selectedPaymentType"
          [toggleItems]="PaymentAndTariffConstants.PAYMENT_TYPES"
          (toggle)="performAction({
            type: InvoicingModalActionTypes.TOGGLE_PAYMENT_TYPE,
            id: $event
          })"
        />
      </div>

      <div
        *ngIf="state.selectedPaymentType === 'invoice'"
        class="flex flex-col gap-[12px]"
      >
        <app-core-input-field
          variant="standard"
          label="Название компании"
          placeholder="Введите название компании"
          [required]="true"
          errorShowType="onUnFocused"
          [error]="state.organisationName.error"
          [defaultValue]="state.organisationName.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_ORGANISATION_NAME,
            value: $event
          })"
        />

        <app-core-input-field
          variant="standard"
          label="Юридический адрес"
          placeholder="Введите юридический адрес"
          [required]="true"
          errorShowType="onUnFocused"
          [error]="state.legalAddress.error"
          [defaultValue]="state.legalAddress.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_LEGAL_ADDRESS,
            value: $event
          })"
        />

        <div class="flex h-[40px] items-center gap-[8px]">
          <app-core-checkbox-field
            [value]="state.isISoloProprietor"
            [label]="'Я индивидуальный предприниматель'"
            (onToggle)="performAction({
              type: InvoicingModalActionTypes.TOGGLE_I_SOLE_PROPRIETOR
            })"
          />
        </div>

        <div class="flex w-full gap-[16px]">
          <app-core-input-field
            class="w-full"
            variant="standard"
            [charsCounterMax]="state.isISoloProprietor ? 12 : 10"
            [required]="true"
            label="ИНН"
            placeholder="Введите ИНН"
            [mask]="'000000000000'"
            errorShowType="onUnFocused"
            [error]="state.INN.error"
            [defaultValue]="state.INN.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_INN,
              value: $event
            })"
          />
          <app-core-input-field
            *ngIf="!state.isISoloProprietor"
            class="w-full"
            variant="standard"
            [charsCounterMax]="9"
            label="КПП"
            placeholder="Введите КПП"
            mask="000000000"
            [required]="true"
            errorShowType="onUnFocused"
            [error]="state.KPP.error"
            [defaultValue]="state.KPP.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_KPP,
              value: $event
            })"
          />
        </div>

        <app-core-input-field
          variant="standard"
          label="Телефон для связи"
          placeholder="Введите номер телефона"
          errorShowType="onUnFocused"
          [required]="true"
          prefix="+"
          mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
          [error]="state.phoneNumber.error"
          [defaultValue]="state.phoneNumber.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_PHONE_NUMBER,
            value: $event
          })"
        />

        <app-core-input-field
          variant="standard"
          label="E-mail для связи"
          placeholder="Введите e-mail"
          errorShowType="onUnFocused"
          [required]="true"
          [error]="state.email.error"
          [defaultValue]="state.email.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_EMAIL,
            value: $event
          })"
        />
      </div>

      <app-receipt
        (onChange)="performAction({
          type: PaymentActionTypes.CHANGE_RECEIPT_PRICE,
          value: $event
        })"
      />

      <app-core-button
        className="w-full"
        size="l"
      >
        <span *ngIf="state.selectedPaymentType === 'card'">Перейти к оплате</span>
        <span *ngIf="state.selectedPaymentType === 'invoice'">Получить счет</span>
      </app-core-button>
    </div>
  </div>

  <p
    class="px-[24px] text-tt-font-size_m text-tt-text-secondary"
  >
    Нажимая
    «<span *ngIf="state.selectedPaymentType === 'card'" class="text-tt-primary cursor-pointer">Перейти к оплате</span>
    <span *ngIf="state.selectedPaymentType === 'invoice'" class="text-tt-primary cursor-pointer">Получить счет</span>»,
    вы соглашаетесь с условиями
    <span class="text-tt-primary cursor-pointer">лицензионного соглашения</span>
    и подтверждаете ознакомление с
    <span class="text-tt-primary cursor-pointer">политикой в отношении обработки и защиты персональных данных.</span>
  </p>
</div>
