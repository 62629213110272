import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { OrderEntity } from "../../../domain/order-entity";
import { getFormatNumberString } from "../../../../../../../core/utils/get-format-number-string";
import { InvoiceStatus, PaymentType } from "../../../../../data/dto/invoices-list-dto";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: [
    "./order.component.scss",
    "../../../../../common/settings.scss"
  ]
})
export class OrderComponent {
  @Input({required: true}) order!: OrderEntity
  @Input() isOpen: boolean = false

  @Output() onCancel = new EventEmitter()

  protected readonly getFormatNumberString = getFormatNumberString;
  protected readonly InvoiceStatus = InvoiceStatus;
  protected readonly PaymentType = PaymentType;
}
