import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsListComponent } from './presentation/news-list.component';
import { SortPopupComponent } from './presentation/components/sort-popup/sort-popup.component';
import {
  SvgBookmark,
  SvgChecklist, SvgDocumentArrowBack,
  SvgGrid2x2,
  SvgHeaderArrow, SvgLink, SvgTripleDot,
  SvgXMark
} from "../../../../core/components/svg-components/svg.components";
import { NewsItemComponent } from './presentation/components/news-item/news-item.component';
import { RouterLink } from "@angular/router";
import { GridListToggleButtonComponent } from './presentation/components/grid-list-toggle-button/grid-list-toggle-button.component';
import { ComponentsModule } from "../../../../core/components/components.module";
import { FullNewsComponent } from "./presentation/components/full-news/full-news.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    NewsListComponent,
    SortPopupComponent,
    NewsItemComponent,
    GridListToggleButtonComponent,
    FullNewsComponent,
  ],
  imports: [
    CommonModule,
    SvgHeaderArrow,
    SvgGrid2x2,
    SvgChecklist,
    RouterLink,
    ComponentsModule,
    SvgXMark,
    SvgDocumentArrowBack,
    SvgBookmark,
    SvgLink,
    SvgTripleDot,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  exports: [
    NewsItemComponent,
    GridListToggleButtonComponent,
  ]
})
export class NewsListModule { }
