<img
  *ngIf="imageSrc != '' && imageSrc"
  [ngSrc]="imageSrc ? imageSrc : 'assets/image-stub-finding.png'"
  [width]="size === 'medium' ? 144 : 200"
  [height]="size === 'medium' ? 144 : 200"
  alt=""
/>

<p
  class="text-tt-font-size_l text-tt-text-main"
  [class.mt-[16px]]="imageSrc != ''"
>
  {{title}}
</p>

<p
  *ngIf="description != ''"
  class="mt-[4px] text-tt-font-size_m text-tt-text-secondary">
  {{description}}
</p>
