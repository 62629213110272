import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NewsService } from "../../../../../data/news-service";
import { ToastsService } from "../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../core/components/toast-alert/toast-alert.component";
import { NewsEditorResultAction } from "../../state/news-editor-result-action";

@Component({
  selector: 'app-input-news-image',
  templateUrl: './input-news-image.component.html',
  styleUrls: ['./input-news-image.component.scss']
})
export class InputNewsImageComponent {
  @Input() imageId: string = '';
  @Input() newsId: string = ''
  @Output() imageUpload = new EventEmitter<string>();

  @ViewChild('inputImage') inputImageRef!: ElementRef;

  imageUrl: string = ''

  constructor(
    private newsService: NewsService,
    private toastsService: ToastsService,
  ) {
    if(this.newsId != '')
    {
      newsService.downloadImage(this.newsId).subscribe({
        next: (result) => {
          this.imageUrl = URL.createObjectURL(result)
        },
        error: err => {
          this.toastsService.createToast({
            title: 'Не удалось загрузить изображение',
            description: '',
            state: ToastState.ERROR
          });
        }
      })
    }
  }

  selectClicked(){
    this.inputImageRef.nativeElement.click()
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file)

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        this.newsService.uploadImage(this.newsId, formData)
          .subscribe({
            next: (image) => {
              this.imageUpload.emit(image.id)
            },
            error: err => {
              this.toastsService.createToast({
                title: 'Не удалось загрузить изображение',
                description: '',
                state: ToastState.ERROR
              });
            }
          })
      }
    }
  }

}
