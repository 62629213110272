import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { TaskEntity, TaskExecutor, TaskPriority, TaskType } from "../task-item/task-item.component";
import { ruNumWord } from "../../../../../core/utils/ru-num-word";
import { AgilesConstants } from "../../common/agiles-constants";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent implements AfterViewInit{
  @Input({required: true}) table!: TasksTableEntity

  @ViewChild('tableEl') tableEl!: ElementRef
  @ViewChildren('rowHeader') rowHeaders!: QueryList<ElementRef>

  selectedTaskEntities: TaskEntity[] = []

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.resizeTable()
    })
  })

  MOCK_TABLE= {
    number: 1,
    projectTag: 'TTP',
    title: 'Надо делать дела',
    description: 'Недела делать не надо',
    priority: TaskPriority.NORMAL,
    type: TaskType.TASK
  }

  taskNumber = 3

  protected readonly ruNumWord = ruNumWord;
  protected readonly AgilesConstants = AgilesConstants;

  constructor(
    private renderer: Renderer2,
  ) {
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.tableEl.nativeElement)
  }

  resizeTable(){
    const width = this.tableEl.nativeElement.offsetWidth;
    this.rowHeaders.forEach((rowHeader) => {
      this.renderer.setStyle(rowHeader.nativeElement, 'width', `${width - 1}px`);
    })
  }

  pushNew(tasks: TaskEntity[]) {
    tasks.push({
      ...this.MOCK_TABLE,
      number: this.taskNumber
    })
    this.taskNumber++
  }

  drop(event: CdkDragDrop<TaskEntity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  addSelectedTask(task: TaskEntity) {
    const findIndex = this.selectedTaskEntities.findIndex(selected => selected == task)
    if(findIndex != -1)
    {
      this.selectedTaskEntities.splice(findIndex, 1);
    }
    else {
      this.selectedTaskEntities.push(task);
    }
  }

  isTaskSelected(task: TaskEntity): boolean {
    return !!this.selectedTaskEntities.find(selected => selected == task);
  }

  unSelect(){
    this.selectedTaskEntities = []
  }

  deleteSelected(){
    this.table = {...this.table,
      rows: this.table.rows.map((row) => {
        return {
          ...row,
          columnsTasks: row.columnsTasks.map(tasks => {
            return tasks.filter(task => !this.selectedTaskEntities.find(selected => selected == task))
          })
        }
      })
    }
    this.unSelect()
  }
}

export interface TasksTableEntity{
  columns: TasksTableColumnEntity[]
  rows: TasksTableRowEntity[]
}

export interface TasksTableColumnEntity{
  name: string,
  isOpen: boolean
}

export interface TasksTableRowEntity{
  isOpen: boolean
  executor: TaskExecutor,
  countTasks: number
  columnsTasks: TaskEntity[][]
}
