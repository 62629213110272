import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ActiveSubscriptionDto } from "./dto/active-subscription-dto";
import { AllTariffsDto } from "./dto/all-tariffs-dto";
import { CreateInvoiceDto } from "./dto/create-invoice-dto";
import { CreateUpdateInvoice } from "./dto/create-update-invoice";
import { InvoiceDto, InvoicesListDto, InvoiceStatus, PaymentType } from "./dto/invoices-list-dto";
import { GetUpdateInvoiceDataDto } from "./dto/get-update-invoice-data-dto";
import { GetCreateInvoiceDataDto } from "./dto/get-create-invoice-data-dto";
import { environment } from "../../../../environments/environment";

const MOCK_INVOICE: InvoiceDto = {
  id: '#112221-423-61',
  createdAt: new Date().getTime(),
  paymentType: PaymentType.CARD,
  totalValue: 24204000,
  status: InvoiceStatus.PENDING,
  details: ''
}

const MOCK_DURATIONS = [
  {
    duration: 1,
    discount: 0,
  },{
    duration: 3,
    discount: 0,
  },{
    duration: 6,
    discount: 0,
  },{
    duration: 12,
    discount: 20,
  },{
    duration: 24,
    discount: 25,
  },{
    duration: 36,
    discount: 30,
  },
]
const MOCK_COSTS = [{
  duration: 1,
  value: 300,
},{
  duration: 3,
  value: 275,
},{
  duration: 6,
  value: 250,
},{
  duration: 12,
  value: 220,
},{
  duration: 24,
  value: 210,
},{
  duration: 36,
  value: 200,
},]

const MOCK_TARIFFS = [
  {
    id: '-1',
    name: 'Не, ну это база',
    costs: MOCK_COSTS,
    features: [
      {
        value: 'Сделаная фича',
        isCompleted: true
      },
      {
        value: 'Несделаная фича',
        isCompleted: false
      },
    ]
  },
  {
    id: '-2',
    name: 'F Discord',
    costs: MOCK_COSTS,
    features: [
      {
        value: 'Сделаная фича',
        isCompleted: true
      },
      {
        value: 'Несделаная фича',
        isCompleted: false
      },
    ]
  }
]

const MOCK_ADDITIONAL_TARIFF = {
    storageCosts: [
      {
        minCount: 0,
        maxCount: 100,
        value: 20,
      },
      {
        minCount: 101,
        maxCount: 99999,
        value: 15,
      }
    ]
  }

@Injectable()
export class SubscriptionService {
  private readonly apiUrl: string = `${environment.apiUrl}/subscriptions`;

  constructor(private http: HttpClient) {}

  getActiveSubscription(): Observable<ActiveSubscriptionDto> {
    return of({
      isEditable: true,
      currentTariff: {id: '-1', name: 'Не, ну это база'},
      expirationDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 30,
      billingPeriod: {
        startDate: new Date().getTime() - 1000 * 60 * 60 * 24 * 60,
        endDate: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
      },
      activeUsers: {
        currentUsersCount: 5,
        totalUsersCount: 25,
      },
      storage: {
        currentSize: 0.04,
        maxSize: 25
      }
    })//this.http.get<ActiveSubscriptionDto>(`${this.apiUrl}/current`);
  }

  getAllTariffs(): Observable<AllTariffsDto> {
    return of({
      currentTariffId: '-1',
      durations: MOCK_DURATIONS,
      baseDuration: 1,
      allTariffs: MOCK_TARIFFS
    })

    //this.http.get<AllTariffsDto>(`${this.apiUrl}/getAllTariffs`);
  }

  getUpdateInvoiceData(): Observable<GetUpdateInvoiceDataDto> {
    return of({
      expandData: {
        userCost: 300,
        monthCountToExpirationDate: 3
      },
      extendData: {
        durations: MOCK_DURATIONS,
        currentUsersCount: 5,
        costs: MOCK_COSTS,
        currentAdditionalStorageSize: 25,
        currentTariffName: 'Не, ну это база'
      },
      additionalTariff: MOCK_ADDITIONAL_TARIFF
    })

    //this.http.get<GetUpdateInvoiceDataDto>(`${this.apiUrl}/getUpdateInvoiceData`)
  }

  getCreateInvoiceData(): Observable<GetCreateInvoiceDataDto>{
    return of({
      durations: MOCK_DURATIONS,
      baseDuration: 1,
      allTariffs: MOCK_TARIFFS,
      additionalTariff: MOCK_ADDITIONAL_TARIFF,
    })//this.http.get<GetCreateInvoiceDataDto>(`${this.apiUrl}/getCreateInvoiceData`)
  }

  createInvoice(invoice: CreateInvoiceDto): Observable<{paymentId: string}>{
    return this.http.post<{paymentId: string}>(`${this.apiUrl}/createInvoice`, invoice)
  }

  createUpdateInvoice(invoice: CreateUpdateInvoice) {
    return this.http.post<{paymentId: string}>(`${this.apiUrl}/createUpdateInvoice`, invoice)
  }

  getInvoicesList(): Observable<InvoicesListDto>{
    return of({
      activeInvoice: MOCK_INVOICE,
      invoices: [
        { ...MOCK_INVOICE, status: InvoiceStatus.CANCELED },
        { ...MOCK_INVOICE, status: InvoiceStatus.PAID },
        { ...MOCK_INVOICE, status: InvoiceStatus.PAID, paymentType: PaymentType.INVOICE },
      ]
    })//this.http.get<InvoicesListDto>(`${this.apiUrl}/getInvoicesList`);
  }

  cancelInvoice(id: string): Observable<InvoicesListDto>{
    return this.http.post<InvoicesListDto>(`${this.apiUrl}/cancelInvoice/${id}`, {});
  }
}
