<div class="flex items-center justify-between gap-tt_l">
  <h2 class="text-tt-font-size_3xl text-tt-text-default font-medium">
    Компетенции и навыки
  </h2>
  <div class="flex flex-row gap-tt_l items-center">
    <app-core-button-icon variant="ghost">
      <svg-add-folder />
    </app-core-button-icon>
    <app-create-skill>
      <app-core-button variant="soft">+ Компетенция</app-core-button>
    </app-create-skill>
    <app-create-skill>
      <app-core-button>+ Навык</app-core-button>
    </app-create-skill>
  </div>
</div>

<app-core-search-field
  className="my-tt_l"
  (onChange)="
    performAction({
      type: CompetenciesAndSkillsActionTypes.CHANGE_SEARCH,
      search: $event
    })
  "
/>

<app-core-skeleton
  *ngIf="state.isLoading && !state.error"
  className="rounded-tt-border-radius_m w-full h-tt-button-height_l"
  gap="8px"
  [amount]="20"
/>

<div *ngIf="state.error">Error!</div>

<div *ngIf="!state.isLoading && !state.error">
  <app-tree-of-competencies-and-skills
    [competenciesAndSkills]="state.competenciesAndSkills"
    [openedFolders]="state.openedFolders"
    [openedCompetencies]="state.openedCompetencies"
    (onOpenFolder)="
      performAction({
        type: CompetenciesAndSkillsActionTypes.OPEN_FOLDER,
        folder: $event
      })
    "
    (onOpenCompetency)="
      performAction({
        type: CompetenciesAndSkillsActionTypes.OPEN_COMPETENCY,
        competency: $event
      })
    "
    (onCloseFolder)="
      performAction({
        type: CompetenciesAndSkillsActionTypes.CLOSE_FOLDER,
        folder: $event
      })
    "
    (onCloseCompetency)="
      performAction({
        type: CompetenciesAndSkillsActionTypes.CLOSE_COMPETENCY,
        competency: $event
      })
    "
  />
</div>
