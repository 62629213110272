import { Component, OnDestroy } from "@angular/core";
import { Store } from "../../../../../../../core/mvi/store";
import { SubscriptionFormState } from "./state/subscription-form-state";
import { SubscriptionFormExecutor } from "./state/subscription-form-executor";
import { SubscriptionFormAction, SubscriptionFormActionTypes } from "./state/subscription-form-action";
import { SubscriptionFormResultAction } from "./state/subscription-form-result-action";
import { SubscriptionFormReducer } from "./state/subscription-form-reducer";
import { ReceiptService } from "../../../data/receipt-service";

@Component({
  selector: 'app-active-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: [
    './subscription-form.component.scss',
    '../../../../../common/settings.scss'
  ],
  providers: [
    SubscriptionFormState,
    SubscriptionFormExecutor,
    SubscriptionFormReducer,
  ]
})
export class SubscriptionFormComponent
  extends Store<SubscriptionFormState, SubscriptionFormExecutor, SubscriptionFormAction, SubscriptionFormResultAction>
  implements OnDestroy
{
  constructor(
    state: SubscriptionFormState,
    executor: SubscriptionFormExecutor,
    reducer: SubscriptionFormReducer,
    private receiptService: ReceiptService
  ) {
    super(state, executor, reducer);
  }

  ngOnDestroy(): void {
    this.receiptService.updateSections([])
  }

  protected readonly SubscriptionFormActionTypes = SubscriptionFormActionTypes;
}
