import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from "./presentation/orders.component";
import { ComponentsModule } from "../../../../core/components/components.module";
import { OrderComponent } from './presentation/components/order/order.component';
import { SvgArrow } from "../../../../core/components/svg-components/svg.components";
import { PaymentAndTariffModule } from "../payment-and-tariff/payment-and-tariff.module";
import { SubscriptionService } from "../../data/subscription.service";

@NgModule({
  declarations: [
    OrdersComponent,
    OrderComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgArrow,
    PaymentAndTariffModule
  ],
  providers: [
    SubscriptionService
  ]
})
export class OrdersModule { }
