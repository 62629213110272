import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { InformerTextType } from "../informer-text/informer-text.component";

@Component({
  selector: 'app-core-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements AfterViewInit{
  @Input() title: string = ''
  @Input() description: string = ''
  @Input() type: InformerTextType = 'info'
  @Input() confirmButtonText: string = ''
  @Input() cancelButtonText: string = ''
  @Input() confirmDisabled: boolean = false
  @Input() isLoading: boolean = false
  @Input() hideContentOnLoad: boolean = false
  @Input() closeActionType: 'confirm' | 'cancel' | 'self' = 'cancel'
  @Input() isInitFocus: boolean = true

  @Output() onConfirmClicked = new EventEmitter()
  @Output() onCancelClicked = new EventEmitter()
  @Output() onCloseClicked = new EventEmitter()

  @ViewChild('closeButton') closeButton!: ElementRef;

  onClose(event: MouseEvent) {
    event.preventDefault()
    switch (this.closeActionType){
      case "cancel":
        this.onCancelClicked.emit()
        break
      case "confirm":
        this.onConfirmClicked.emit()
        break
      case "self":
        this.onCloseClicked.emit()
        break
    }
  }

  ngAfterViewInit() {
    if(this.isInitFocus){
      this.closeButton.nativeElement.focus()
      this.closeButton.nativeElement.blur()
    }
  }
}
