import { dateToDMonthYString } from "./dateToDMonthYString";

export const dateToLastTimeString = (date: Date, yearPostfix: string = ''): string => {
  const nowDay = new Date().setHours(0, 0, 0, 0);
  const dateDay = new Date(date.getTime()).setHours(0, 0, 0, 0)
  const daysBetweenNow = (nowDay - dateDay) / (1000 * 60 * 60 * 24);
  const userTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const userLocalTime = new Date(date.getTime());

  switch (daysBetweenNow){
    case 0:
      return `сегодня ${userLocalTime.getHours()}:${(userLocalTime.getMinutes().toString().length == 1 ? "0" : '') + userLocalTime.getMinutes()}`;
    case 1:
      return `вчера ${userLocalTime.getHours()}:${(userLocalTime.getMinutes().toString().length == 1 ? "0" : '') + userLocalTime.getMinutes()}`
    default:
      return dateToDMonthYString(date) + yearPostfix
  }
};
