import { PaymentAndTariffState } from "./payment-and-tariff-state";
import { PaymentAndTariffAction } from "./payment-and-tariff-action";
import { PaymentAndTariffResultAction, PaymentAndTariffResultActionTypes } from "./payment-and-tariff-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { ActivatedRoute } from "@angular/router";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { TariffEntity } from "../../domain/tariff-entity";
import { TariffDuration } from "../../domain/tariff-duration";
import { ruNumWord } from "../../../../../../core/utils/ru-num-word";
import { SettingsConstants } from "../../../../common/settings-constants";
import { ActiveSubscriptionDto } from "../../../../data/dto/active-subscription-dto";
import { AllTariffsDto } from "../../../../data/dto/all-tariffs-dto";
import { SubscriptionService } from "../../../../data/subscription.service";

@Injectable()
export class PaymentAndTariffExecutor extends Executor<
  PaymentAndTariffState,
  PaymentAndTariffAction,
  PaymentAndTariffResultAction
> {

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<PaymentAndTariffState, PaymentAndTariffResultAction>,
    getState: () => PaymentAndTariffState,
    onReduced: (state: PaymentAndTariffState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: PaymentAndTariffAction) {
    switch (action.type) {

    }
  }

  private handleInit(){
    this.changeOpenedModal(this.route.snapshot.queryParamMap.get("open"))
    this.route.queryParamMap.subscribe(paramMap => {
      this.changeOpenedModal(paramMap.get("open"));
    })

    this.reduce({
      type: PaymentAndTariffResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    let activeSubscriptionIsLoaded = false;
    let tariffsIsLoaded = false;
    let errorShowed = false

    const endLoad = (withError: boolean = false) => {
      if(!errorShowed){
        if(withError){
          errorShowed = true
          this.toastsService.createToast({
            title: 'Произошла ошибка при загрузке тарифов',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
        } else if (activeSubscriptionIsLoaded && tariffsIsLoaded){
          this.reduce({
            type: PaymentAndTariffResultActionTypes.CHANGE_IS_LOADING,
            value: false
          })
        }
      }
    }

    this.subscriptionService.getActiveSubscription().subscribe({
      next: (activeSubscription) => {
        this.handleInitActiveSubscription(activeSubscription)
        activeSubscriptionIsLoaded = true
        endLoad()
      },
      error: () =>{
        activeSubscriptionIsLoaded = true
        endLoad(true)
      }
    })

    this.subscriptionService.getAllTariffs().subscribe({
      next: (dto) => {
        this.handleInitTariffs(dto);
        tariffsIsLoaded = true
        endLoad()
      },
      error: () =>{
        tariffsIsLoaded = true
        endLoad(true)
      }
    })
  }

  private handleInitTariffs(dto: AllTariffsDto){
    const tariffs = dto.allTariffs.map<TariffEntity>((tariff) => {
      return {
        id: tariff.id,
        title: tariff.name,
        prices: tariff.costs,
        features: tariff.features.map((feature) => {
          return {
            title: feature.value,
            soon: !feature.isCompleted
          }
        }),
      }
    })
    let currentDuration: TariffDuration | null = null
    const tariffDurations = dto.durations.map<TariffDuration>((duration)=> {
      const result: TariffDuration = {
        name: `на ${duration.duration} ${ruNumWord(duration.duration, SettingsConstants.MONTH_STRINGS)}`,
        countMonth: duration.duration,
        discountPercent: duration.discount
      }
      if (duration.duration == dto.baseDuration){
        currentDuration = result
      }
      return result
    })
    this.reduce({
      type: PaymentAndTariffResultActionTypes.INIT_TARIFFS,
      tariffs: tariffs,
      currentTariff: tariffs.find((tariff) => tariff.id === dto.currentTariffId),
      durations: tariffDurations,
      currentDuration: currentDuration
    })
  }

  private handleInitActiveSubscription(activeSubscription: ActiveSubscriptionDto) {
    this.reduce({
      type: PaymentAndTariffResultActionTypes.INIT_ACTIVE_SUBSCRIPTION,
      isEditable: activeSubscription.isEditable,
      subscription: {
        tariff: activeSubscription.currentTariff,
        expirationDate: new Date(activeSubscription.expirationDate),
        billingPeriodStart: new Date(activeSubscription.billingPeriod.startDate),
        billingPeriodEnd: new Date(activeSubscription.billingPeriod.endDate),
        activeUsersCount: activeSubscription.activeUsers.currentUsersCount,
        activeUsersMax: activeSubscription.activeUsers.totalUsersCount,
        occupiedStorageSpace: activeSubscription.storage.currentSize,
        maxStorageSpace: activeSubscription.storage.maxSize,
      }
    })
  }

  private changeOpenedModal(modalStr: string | null){
    this.reduce({
      type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_SUBSCRIPTION_FORM,
      value: false
    })
    this.reduce({
      type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_TARIFF_FORM,
      value: false
    })
    switch (modalStr){
      case 'subscription':
        this.reduce({
          type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_SUBSCRIPTION_FORM,
          value: true
        })
        break;
      case 'tariff':
        this.reduce({
          type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_TARIFF_FORM,
          value: true
        })
        break;
    }
  }
}
