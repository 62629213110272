<app-core-alert-modal
  [title]="'Новое пространство'"
  type="info"
  [cancelButtonText]="'Отмена'"
  [isLoading]="isCreating"
  [isInitFocus]="false"
  [confirmButtonText]="'Создать'"
  [confirmDisabled]="spaceName == '' || spaceNameError != null"
  (onConfirmClicked)="create()"
  (onCancelClicked)="closeClicked.emit()"
>
  <app-core-input-field
    label="Введите название пространства"
    variant="standard"
    placeholder=""
    error="{{ spaceNameError }}"
    errorShowType="onUnFocused"
    [autoFocus]="true"
    [charsCounterMax]="100"
    [required]="true"
    (onChange)="spaceNameChange($event)"
  />
</app-core-alert-modal>
