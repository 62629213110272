<app-core-page-shading
  [appearanceType]="'animate'"
>
  <div
    class="w-full h-full flex justify-center items-center"
    cdkTrapFocus
  >
    <div class="
      p-[24px] rounded-[16px] max-w-[512px] w-full bg-tt-modal-background
      border border-tt-border-default animate-alert_appearance
      relative overflow-hidden"
    >
      <app-core-loading-shading
        *ngIf="isLoading"
      />

      <div
        [class.invisible]="hideContentOnLoad && isLoading"
      >
        <div class="w-full flex justify-between items-center"
        >
          <p class="font-normal text-tt-font-size_3xl text-tt-informer-title">{{title}}</p>
          <button
            class="size-[40px] flex justify-center items-center text-tt-informer-close-icon rounded-[20px] hover:bg-tt-icon-button-background-hover outline-tt-informer-info-icon"
            (click)="onClose($event)"
            #closeButton
          >
            <svg-x-mark class="size-[24px]"/>
          </button>
        </div>

        <div
          class="mt-[8px]"
        >
          <app-core-informer-text
            [type]="type"
            [informerText]="description"
          />
        </div>

        <div
          class="mt-[16px] pb-[24px] text-tt-font-size_l text-tt-informer-value"
        >
          <ng-content/>
        </div>

        <div class="flex justify-end gap-[16px]">
          <app-core-button
            *ngIf="cancelButtonText != ''"
            variant="soft"
            (onClick)="onCancelClicked.emit()"
          >
            {{cancelButtonText}}
          </app-core-button>

          <app-core-button
            *ngIf="confirmButtonText != ''"
            [type]="type == 'info' ? 'basic' : 'danger'"
            (onClick)="onConfirmClicked.emit()"
            [disabled]="confirmDisabled"
          >
            {{confirmButtonText}}
          </app-core-button>
        </div>
      </div>

    </div>

  </div>
</app-core-page-shading>
