<div class="child-routes-size px-[24px] tiny-scrollbar"
     (scroll)="onScroll()"
     #scrollable
>
  <div
    class="mt-[44px] w-full flex justify-center"
    *ngIf="state.isLoading"
  >
    <mat-spinner class="size-[64px]"></mat-spinner>
  </div>
  <div
    *ngIf="!state.isLoading"
  >
    <app-core-page-header
      textSize="5xl"
      [title]="state.mainData.name"
    >
      {{state.mainData.description}}
    </app-core-page-header>

    <div class="w-full flex flex-col items-center py-[24px]">
      <app-core-settings-container>
        <app-core-settings-nav-container
          [title]="'Фильтры для разделов'"
        >
          <app-core-settings-nav-item
            [isSelected]="state.currentNavItem == RoleNavItems.MAIN"
            (onClick)="performAction({
            type: RoleActionTypes.CHANGE_NAV_ITEM,
            navItem: RoleNavItems.MAIN
          })"
          >
            <svg-space-settings-home/>
            <p>Основное</p>
          </app-core-settings-nav-item>

          <app-core-settings-nav-item
            [isSelected]="state.currentNavItem == RoleNavItems.PERMISSIONS"
            (onClick)="performAction({
          type: RoleActionTypes.CHANGE_NAV_ITEM,
          navItem: RoleNavItems.PERMISSIONS
        })"
          >
            <svg-person/>
            <p>Разрешения доступа</p>
          </app-core-settings-nav-item>

        </app-core-settings-nav-container>

        <app-role-main
          *ngIf="state.currentNavItem == RoleNavItems.MAIN"
          [data]="state.mainData"
          [isReadOnly]="!state.isCustom || !state.canEdit"
          (onSave)="performAction({
          type: RoleActionTypes.SAVE_MAIN_DATA,
          data: $event
        })"
        />

        <app-role-permissions
          *ngIf="state.currentNavItem == RoleNavItems.PERMISSIONS"
          [permissions]="state.permissions"
          [isReadOnly]="!state.isCustom || !state.canEdit"
          (onSave)="performAction({
          type: RoleActionTypes.SAVE_PERMISSIONS,
          permissions: $event
        })"
        />
      </app-core-settings-container>

      <div
        *ngIf="state.isCustom"
        class="mt-[48px] max-w-[935px] w-full h-[1px] bg-tt-border-header-popup-without-alpha"
      ></div>

      <app-core-settings-button
        *ngIf="state.canDelete && state.isCustom"
        class="mt-[24px] flex justify-center"
        (onClick)="performAction({
        type: RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
        value: true
      })"
      >
        <svg-trash class="min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"/>
        <p class="text-[14px]">Удалить роль</p>
      </app-core-settings-button>
    </div>

  </div>
</div>

<app-core-alert-modal
  *ngIf="state.isDeleteAlertVisible"
  [title]="'Удалить роль'"
  type="warning"
  [cancelButtonText]="'Отменить'"
  [confirmButtonText]="'Удалить'"
  [isLoading]="state.isDeleting"
  (onConfirmClicked)="performAction({
    type: RoleActionTypes.DELETE
  })"
  (onCancelClicked)="performAction({
    type: RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
>
  <p>Роль с именем “<span class="font-medium">{{state.mainData.name}}</span>” будет удалена. Вы уверены, что хотите удалить эту роль?</p>

</app-core-alert-modal>

<div
  class="fixed left-0 top-0 w-screen h-screen z-50"
  *ngIf="state.isPageLoading"
>
  <app-core-loading-shading/>
</div>
