import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ReceiptSection } from "../../../domain/receipt-secton";
import { ReceiptService } from "../../../data/receipt-service";
import { getFormatNumberString } from "../../../../../../../core/utils/get-format-number-string";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnChanges {
  @Input() sections: ReceiptSection[] = []
  @Output() onChange = new EventEmitter<number>()

  finalPrice: number = 0

  constructor(
    receiptService: ReceiptService
  ) {
    receiptService.receiptSections.subscribe((sections) => {
      this.sections = sections
      this.calcFinalPrice()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calcFinalPrice()
  }

  calcFinalPrice(){
    this.finalPrice = 0
    this.sections.forEach(section => {
      section.fields.forEach(field => {
        this.finalPrice += field.price
      })
    })
    this.onChange.emit(this.finalPrice)
  }

  protected readonly getFormatNumberString = getFormatNumberString;
}
