import {
  Elements,
  findSlateBySelectionPath,
  SlateElement,
  UI,
  useYooptaEditor,
  useYooptaTools
} from "@teamtells/editor";
import { useState } from "react";
import { autoUpdate, flip, inline, offset, shift, useFloating, useTransitionStyles } from "@floating-ui/react";
import { Editor, Element, Transforms } from "slate";
import { LinkElementProps } from "@teamtells/link";
import { ReactSVG } from "react-svg";
import { ToastsService } from "../../../../toast-alert/services/toast-alert.service";
import { AngularWrapper, useInjected } from "@bubblydoo/angular-react";
import { ToastState } from "../../../../toast-alert/toast-alert.component";
import { TooltipBoxComponent } from "../../../components/tooltip-box/tooltip-box.component";
import { YooptaEditorConstants } from "../../../common/yoopta-editor-constants";

const { Overlay, Portal } = UI;

const SVG_PATH = YooptaEditorConstants.SVG_PATH + 'link/'

const LinkHoverPreview = ({ style, setFloating, element, setHoldLinkTool, blockId, onClose }: any) => {
  const editor = useYooptaEditor();
  const tools = useYooptaTools();
  const [isEditLinkToolsOpen, setIsEditLinkToolsOpen] = useState(false);
  const toastsService = useInjected(ToastsService)

  const {
    refs: linkToolRefs,
    floatingStyles: linkToolStyles,
    context,
  } = useFloating({
    placement: 'bottom',
    open: isEditLinkToolsOpen,
    onOpenChange: (open) => {
      setIsEditLinkToolsOpen(open);
    },
    middleware: [inline(), flip(), shift(), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const { isMounted: isLinkToolMounted, styles: linkToolTransitionStyles } = useTransitionStyles(context, {
    duration: {
      open: 100,
      close: 100,
    },
  });

  const linkToolEditStyles = { ...linkToolStyles, ...linkToolTransitionStyles, maxWidth: 400 };

  const LinkTool = tools?.["LinkTool"];
  const hasLinkTool = !!LinkTool;

  const onSave = (linkProps: LinkElementProps) => {
    Elements.updateElement(editor, blockId, {
      type: element.type,
      props: { ...element.props, ...linkProps },
    });

    setIsEditLinkToolsOpen(false);
    onClose();
  };

  const onDelete = () => {
    const slate = findSlateBySelectionPath(editor);
    const path = Elements.getElementPath(editor, blockId, element);

    if (!slate) return;
    const linkNodeEntry = Elements.getElementEntry(editor, blockId, { path, type: element.type });

    if (linkNodeEntry) {
      Transforms.unwrapNodes(slate, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && (n as SlateElement).type === element.type,
        at: path || linkNodeEntry?.[1],
      });
    }
  };

  const onOpenLink = () => {
    window.open(element.props.url, element.props.target || '_self');
  };

  return (
    <Portal id="yoopta-link-preview">
      {isLinkToolMounted && hasLinkTool && (
        <Portal id="yoopta-link-tool">
          <Overlay lockScroll className="yoo-link-z-[100]" onClick={onClose}>
            <div ref={linkToolRefs.setFloating} style={linkToolEditStyles} onClick={(e) => e.stopPropagation()}>
              <LinkTool editor={editor} link={element.props} onSave={onSave} onDelete={onDelete} withTitle={false} />
            </div>
          </Overlay>
        </Portal>
      )}
      <div className="yoopta-link-preview" style={{...style, boxShadow: '0px 1px 4px 0px #00000040',}} ref={setFloating}>
        <span className="yoopta-link-preview-text user-select-none">{element.props.url}</span>
        <div className="yoopta-toolbar-separator" />
        <AngularWrapper
          component={TooltipBoxComponent}
          inputs={{ tooltip: 'Скопировать ссылку', position: 'above' }}
        >
          <button
            type="button"
            onClick={() => {
              toastsService.createToast({
                title: "Ссылка скопирована",
                description: "",
                state: ToastState.SUCCESS
              });
              navigator.clipboard.writeText(element.props.url);
            }}
            className="yoopta-button yoopta-toolbar-item yoopta-link-hover-button"
          >
            <ReactSVG src={SVG_PATH + "copy.svg"} />
          </button>
        </AngularWrapper>

        <div className="yoopta-toolbar-separator" />
        <AngularWrapper
          component={TooltipBoxComponent}
          inputs={{ tooltip: 'Перейти по ссылке', position: 'above' }}
        >
          <button type="button" onClick={onOpenLink}
                  className="yoopta-button yoopta-toolbar-item yoopta-link-hover-button">
            <ReactSVG src={SVG_PATH + "move-to.svg"} />
          </button>
        </AngularWrapper>
        <div className="yoopta-toolbar-separator" />
        <button
          ref={linkToolRefs.setReference}
          type="button"
          className="yoopta-button yoopta-toolbar-item yoopta-link-hover-button text-tt-font-size_l text-tt-text-main p-[8px] w-auto"
          onClick={() => {
            setHoldLinkTool((prev: any) => !prev);
            setIsEditLinkToolsOpen((prev) => !prev);
          }}
        >
          Изменить
        </button>
      </div>
    </Portal>
  );
};

export { LinkHoverPreview };
