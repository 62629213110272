import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { RoleService } from "../../../../../../../../../core/services/role-service/role-service";
import { EmployeesConstants } from "../../../../../../../common/employees-constants";
import {
  HighlightedPartsStr
} from "../../../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { parseToHighlightedParts } from "../../../../../../../../../core/utils/parse-to-highlighted-parts";
import { ToastsService } from "../../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../../core/components/toast-alert/toast-alert.component";
import { RoleSummeryEntity } from "../../../../../../../domain/role";

@Component({
  selector: 'employees-roles-popup',
  templateUrl: './roles-popup.component.html',
  styleUrls: ['./roles-popup.component.scss']
})
export class RolesPopupComponent {
  @Input() activeRoleIds: string[] = []
  @Output() roleClicked = new EventEmitter<RolesPopupEntity>();

  @ViewChild('rolesList') rolesList?: ElementRef;

  roles: RolesPopupEntity[] = [];
  filteredRoles: RolesPopupEntity[] = [];

  popupVisible: boolean = false
  isRolesLoaded = false

  constructor(
    private roleService: RoleService,
    private renderer: Renderer2,
    private toastsService: ToastsService,
  ) {
  }

  openPopup(){
    this.filterRoles('')
    this.popupVisible = !this.popupVisible

    if(!this.isRolesLoaded){
      this.roleService.getNewUserRoles().subscribe({
        next: (roles) => {
          const mappedRoles = roles.items.map<RolesPopupEntity>((role) => {
            if (!role.isCustom) {
              const baseRole = EmployeesConstants.BASE_ROLES.get(role.name);
              if (baseRole) {
                role.name = baseRole.name;
                role.description = baseRole.description;
              }
            }
            return {
              id: role.id,
              name: role.name,
              description: role.description,
              isCustom: role.isCustom,
              isEditable: true,
              nameParts: parseToHighlightedParts(role.name, "")
            };
          }).sort((a, b) => {
            if(a.isCustom)
            {
              return b.isCustom ? (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : 1
            }
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
          })

          this.roles = mappedRoles;
          this.filteredRoles = mappedRoles;
          this.isRolesLoaded = true;
        },
        error: () => {
          this.toastsService.createToast({
            title: 'Не удалось загрузить список ролей',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
          this.popupVisible = false
        }
      })
    }
  }

  filterRoles(filterString: string){
    if(this.rolesList?.nativeElement){
      this.renderer.setStyle(
        this.rolesList?.nativeElement,
        'height',
        this.rolesList?.nativeElement.offsetHeight + 'px',
        1
      )
    }

    this.filteredRoles = this.roles.filter((role)=>{
      if(role.name.toLowerCase().includes(filterString.toLowerCase())){
        role.nameParts = parseToHighlightedParts(role.name, filterString)
        return true
      }
      return false
    })
  }

  roleClick(role: RolesPopupEntity){
    this.roleClicked.emit(role);
    this.popupVisible = false
  }

  closePopup(){
    this.popupVisible = false
    this.filterRoles('')
  }
}

export interface RolesPopupEntity{
  id: string
  name: string
  description: string
  isCustom: boolean
  isEditable : boolean
  nameParts: HighlightedPartsStr[]
}
