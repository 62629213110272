import { NewsListState } from "./news-list-state";
import { NewsListAction, NewsListActionTypes } from "./news-list-action";
import { NewsListResultAction, NewsListResultActionTypes } from "./news-list-result-action";
import { Injectable } from "@angular/core";
import { Executor } from "src/app/core/mvi/store";
import { NewsService } from "../../../../data/news-service";
import { NewsSummaryEntity } from "../../domain/news-summary-entity";
import { dateToLastTimeString } from "../../../../../../core/utils/dateToLastTimeString";
import { NewsNavigator } from "../../../../navigator/news-navigator.service";
import { ActivatedRoute, Params } from "@angular/router";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class NewsListExecutor extends Executor<
  NewsListState,
  NewsListAction,
  NewsListResultAction
> {

  constructor(
    private newsService: NewsService,
    private newsNavigator: NewsNavigator,
    private route: ActivatedRoute,
    private toastsService: ToastsService
  ) {
    super();
    this.handleInit()
  }

  execute(action: NewsListAction) {
    switch (action.type) {
      case NewsListActionTypes.CHANGE_LIST_TYPE:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_LIST_TYPE,
          listType: action.listType
        })
        break;
      case NewsListActionTypes.DELETE_NEWS:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: true
        })
        this.newsService.removeNews(action.id).subscribe({
          next: () => {
            this.reduce({
              type: NewsListResultActionTypes.UPDATE_NEWS_LIST,
              newsList: this.getState().news.filter((news) => news.id !== action.id),
            })
            this.newsNavigator.navigateToList()
            this.reduce({
              type: NewsListResultActionTypes.CLOSE_FULL_NEWS
            })
            this.reduce({
              type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          },
          error: () => {
            this.toastsService.createToast({
              title: 'Не удалось удалить новость',
              description: '',
              state: ToastState.ERROR
            })
            this.reduce({
              type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          },
        })
        break
      case NewsListActionTypes.CREATE_NEWS:
        this.handleCreateNews()
        break
      case NewsListActionTypes.CREATE_NEW_DRAFT:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_IS_LOADING,
          value: true
        })
        this.createNews()
        break
      case NewsListActionTypes.CONTINUE_DRAFT:
        this.handleContinueDraft()
        break
      case NewsListActionTypes.CLOSE_DRAFT_MODAL:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_VISIBILITY,
          value: false
        })
        break
      case NewsListActionTypes.OPEN_FULL_NEWS:
        this.reduce({
          type: NewsListResultActionTypes.OPEN_FULL_NEWS,
          news: action.news
        })
        break
      case NewsListActionTypes.CLOSE_FULL_NEWS:
        this.reduce({
          type: NewsListResultActionTypes.CLOSE_FULL_NEWS
        })
        this.newsNavigator.navigateToList()
        break
    }
  }

  private handleContinueDraft(){
    const draft = this.getState().draft
    if(draft && draft.id)
    {
      this.newsNavigator.navigateToCreate(draft.id)
    }
  }

  private handleCreateNews(){
    this.reduce({
      type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
      value: true
    })
    this.newsService.getDraft().subscribe({
      next: draftDto => {
        if(draftDto.id){
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_DRAFT,
            draft: draftDto
          })
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
            value: false
          })
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_VISIBILITY,
            value: true
          })
        }
        else {
          this.createNews()
        }
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Не удалось получить информацию о черновике',
          description: '',
          state: ToastState.ERROR
        })

        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: false
        })
      }
    })
  }

  private handleInit(){
    this.newsService.getNewsList().subscribe({
      next: (newsListDto) => {
        this.reduce({
          type: NewsListResultActionTypes.LOAD_LIST,
          canCreate: newsListDto.canCreate,
          canEdit: newsListDto.canEdit,
          news: newsListDto.news.map<NewsSummaryEntity>((news)=>{
            const lastEditDate = news.publishedAt > news.updatedAt ?
              new Date(news.publishedAt * 1000) : new Date(news.updatedAt * 1000)
            return {
              id: news.id,
              title: news.title,
              description: news.description,
              longreadId: news.longreadID,
              imageId: news.imageID,
              dateStr: dateToLastTimeString(lastEditDate),
              lastEditDate: lastEditDate
            }
          }).sort((a, b) => a.lastEditDate! > b.lastEditDate! ? -1 : 1)
        })
        this.route.queryParams.subscribe((params)=>{
          this.openNewsByQuery(params);
        })
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при получении списка новостей',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
      }
    })
  }

  private openNewsByQuery(params: Params){
    const id = params['newsId']
    if(id)
    {
      const news = this.getState().news
      const findNews = news.find((news)=> news.id === id)
      if(findNews){
        this.execute({
          type: NewsListActionTypes.OPEN_FULL_NEWS,
          news: findNews
        })
      }
    }
  }

  private createNews(){
    this.newsService.createNews({
      title: '',
      description: '',
    }).subscribe({
      next: createNewsDto =>{
        this.newsNavigator.navigateToCreate(createNewsDto.id)
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_IS_LOADING,
          value: false,
        })
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: false,
        })
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при создании новости',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_IS_LOADING,
          value: false,
        })
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: false,
        })
      },
    })
  }
}
