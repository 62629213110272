<div class="relative"
  appClickOutside
  (clickOutside)="isPopupOpen = false"
>
  <app-core-button-icon
    (click)="isPopupOpen = !isPopupOpen"
  >
    <svg-triple-dot class="min-w-[24px] min-h-[24px]"/>
  </app-core-button-icon>

  <app-core-action-menu
    class="absolute top-[4px] right-0 z-50"
    *ngIf="isPopupOpen"
    [sections]="SpaceConstants.TREE_POPUP_MENU_SECTIONS"
    (elementClicked)="popupElementClicked($event)"
  />
</div>
