import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsEditorComponent } from "./presentation/news-editor.component";
import { ComponentsModule } from "../../../../core/components/components.module";
import { SvgDocumentArrowBack, SvgXMark } from "../../../../core/components/svg-components/svg.components";
import { InputNewsImageComponent } from "./presentation/components/input-news-image/input-news-image.component";
import { NewsListModule } from "../news-list/news-list.module";
import { EditorChangesService } from "../../../../core/components/yoopta-editor/data/editor-changes-service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Validator } from "../../../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../../../core/validators/rule";

@NgModule({
  declarations: [
    NewsEditorComponent,
    InputNewsImageComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgDocumentArrowBack,
    SvgXMark,
    NewsListModule,
    MatProgressSpinnerModule
  ],
  providers: [
    EditorChangesService,
    {
      provide: 'NewsTitleValidator',
      useExisting: Validator,
      useFactory: NewsEditorModule.titleValidatorFactory,
    },
    {
      provide: 'NewsDescriptionValidator',
      useExisting: Validator,
      useFactory: NewsEditorModule.descriptionValidatorFactory,
    },
  ]
})
export class NewsEditorModule {
  public static titleValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите заголвок'),
      new MaxLengthRule('Заголовок не может быть больше 128 символов', 128),
    ]);

  public static descriptionValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите описание'),
      new MaxLengthRule('Описание не может быть больше 180 символов', 180),
    ]);
}
