<div
  class="relative w-full"
  [draggable]="canEdit"
  (dragstart)="dragStart($event)"
  (dragend)="dragEnd($event)"
  [ngClass]="{
    'z-10': isDragOver,
  }"
  #draggableDoc
>
  <div
    *ngIf="isDragOverTopBox && document.parentId === undefined"
    class="drop-line drop-line-top-offset"
  ></div>

  <div
    *ngIf="isDragOverBottomBox && !(document.isOpen && document.documents.length > 0) && document.parentId === undefined"
    class="drop-line drop-line-bottom-offset"
  ></div>

  <button
    class="group py-[4px] flex items-center w-full gap-[12px] min-h-[32px] relative"
    [style.padding-left]="leftOffset + 'px'"
    [style.padding-right]="rightOffset + 'px'"
    [ngClass]="{
      'bg-tt-selected-document-color text-tt-primary': isSelected,
      'hover:bg-tt-light-blue text-tt-text-default': !isSelected,
      'child-pointer-events-none': isDragOver,
    }"
    (click)="documentClick($event)"
    (dragenter)="dragEnter($event)"
    (dragover)="dragOver($event)"
    (dragleave)="dragLeave($event)"
    (drop)="dragDrop($event)"
    #dragContainerDoc
  >
    <div
      *ngIf="isDragOverTopBox && document.parentId !== undefined"
      class="drop-line drop-line-top-offset"
      [style.width]="'calc(100% - '+ leftOffset + 'px)'"
    ></div>

    <div
      *ngIf="isDragOverCenterBox"
      class="h-[32px] w-full absolute left-0 top-0 border-[2px] border-tt-primary"
    ></div>

    <div
      *ngIf="isDragOverBottomBox && ((document.isOpen && document.documents.length > 0) || document.parentId !== undefined)"
      class="drop-line drop-line-bottom-offset"
      [style.width]="'calc(100% - '+ leftOffset + 'px)'"
    ></div>

    <div
      class="min-w-[24px] min-h-[24px] text-tt-icon-default flex justify-center items-center group-hover:hidden"
    >
      <svg-doc-text/>
    </div>

    <button
      class="min-w-[24px] min-h-[24px] max-w--w-[24px] max-w-h-[24px] rounded-[4px] text-tt-icon-default hidden group-hover:block"
      (click)="arrowClick()"
      [ngClass]="{
        'rotate-90': document.isOpen,
        'hover-button': !isSelected,
        'hover-button-select': isSelected,
      }"
      #arrowButton
    >
      <svg-arrow class="min-w-[24px] min-h-[24px] max-w--w-[24px] max-w-h-[24px]"/>
    </button>

    <p class="font-normal text-left text-tt-font-size_l h-full w-full truncate"
    >
      <span
        app-core-with-highlighting
        [text]="titleStrings"></span>
      <span *ngIf="document.countSearchMatches > 1">(Встречено {{document.countSearchMatches}} раз)</span>
    </p>

    <div
      class="w-max max-h-[24px] text-tt-icon-default gap-[12px]"
      [ngClass]="{
        'flex': isPopupOpen,
        'hidden group-hover:flex': !isPopupOpen,
      }"
      *ngIf="canEdit"
    >
      <div
        class="relative"
        appClickOutside
        (clickOutside)="isPopupOpen = false"
        #tripleDotButton
      >
        <button
          class="w-[24px] h-[24px] rounded-[4px]"
          [ngClass]="{
            'hover-button': !isSelected,
            'hover-button-select': isSelected,
          }"
          (click)="isPopupOpen = !isPopupOpen"
        >
          <svg-triple-dot class="min-w-[24px] min-h-[24px]"/>
        </button>

        <app-core-action-menu
          *ngIf="isPopupOpen"
          [sections]="SpaceConstants.TREE_POPUP_MENU_SECTIONS"
          (elementClicked)="popupElementClicked($event)"
          appPopup
          (popupClose)="isPopupOpen = false"
        />
      </div>

      <button
        class="w-[24px] h-[24px] rounded-[4px]"
        [ngClass]="{
          'hover-button': !isSelected,
          'hover-button-select': isSelected,
        }"
        (click)="createClicked.emit(document.id)"
        #plusButton
      >
        <svg-plus class="min-w-[24px] min-h-[24px]"/>
      </button>
    </div>

  </button>


  <div
    *ngIf="document.isOpen"
  >
    <space-document-item
      *ngFor="let doc of document.documents"
      [document]="doc"
      [canEdit]="canEdit"
      [searchStr]="searchStr"
      [selectedId]="selectedId"
      [depth]="depth + 1"
      [canDrop]="canDrop"
      (onStartDrag)="childStartDrag()"
      (onEndDrag)="childEndDrag()"
      (moveTo)="moveTo.emit($event)"
      (documentClicked)="documentClicked.emit($event)"
      (createClicked)="createClicked.emit($event)"
      (editClicked)="editClicked.emit($event)"
      (renameClicked)="renameClicked.emit($event)"
      (deleteClicked)="deleteClicked.emit($event)"
    />
  </div>
</div>
