<div class="h-screen flex flex-col relative">
  <div class="bg-white flex align-middle justify-between w-full min-h-[60px] max-h-[60px] gap-[40px] border-b-[1px] border-tt-separator-color">
    <div class="flex items-center overflow-hidden flex-grow">
      <app-core-button-icon class="ml-[16px]"
        (click)="performAction({
          type: DocumentActionTypes.BACK_CLICKED
        })"
      >
        <svg-document-back-arrow/>
      </app-core-button-icon>

      <p class="ml-[16px] flex-grow line-clamp-1 py-[2px] px-[8px] rounded-tt-border-radius_s text-tt-text-main">
        {{state.title == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME: state.title}}
      </p>

    </div>
    <div class="mr-[24px] my-auto flex items-center gap-[16px]">
      <app-core-button (onClick)="performAction({
        type: DocumentActionTypes.SAVE_CLICKED
      })">
        Сохранить изменения
      </app-core-button>
    </div>
  </div>

  <app-yoopta-editor
    class="pt-[40px] w-full flex justify-center flex-grow overflow-y-auto"
    id="yoopta-editor-scroll"
    [longreadId]="state.longreadId"
    [isReadOnly]="false"
    [isHaveTitle]="true"
    [title]="state.title"
    (titleChange)="performAction({
      type: DocumentActionTypes.CHANGE_TITLE,
      title: $event
    })"
    (saveClicked)="performAction({
      type: DocumentActionTypes.SAVE_CLICKED
    })"
    #editor
  />
</div>

<app-core-alert-modal
  *ngIf="state.saveModalVisible"
  [title]="'Изменения сохранены'"
  type="info"
  [isLoading]="state.isLoadingSaveModal"
  [hideContentOnLoad]="true"
  [cancelButtonText]="'Продолжить'"
  [confirmButtonText]="'Вернуться к пространству'"
  (onConfirmClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
  (onCancelClicked)="performAction({
    type: DocumentActionTypes.CLOSE_GO_TO_SPACE_MODAL
  })"
>
  <p>Хотите продолжить редактирование или вернуться в пространство?</p>

</app-core-alert-modal>

<app-core-alert-modal
  *ngIf="state.backModalVisible"
  [title]="'Сохранить изменения?'"
  type="info"
  closeActionType="self"
  [isLoading]="state.isLoadingBackModal"
  [hideContentOnLoad]="true"
  [cancelButtonText]="'Выйти'"
  [confirmButtonText]="'Сохранить и выйти'"
  (onCancelClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
  (onConfirmClicked)="performAction({
    type: DocumentActionTypes.SAVE_AND_MOVE
  })"
  (onCloseClicked)="performAction({
    type: DocumentActionTypes.CLOSE_BACK_MODAL
  })"
>
  <p>У вас есть несохраненные изменения, вы действительно хотите покинуть режим редактирования?</p>

</app-core-alert-modal>

<app-core-alert-modal
  *ngIf="state.afkModalVisible"
  [title]="'Вы еще здесь?'"
  type="info"
  closeActionType="confirm"
  [isLoading]="state.isLoadingBackModal"
  [hideContentOnLoad]="true"
  [cancelButtonText]="'Сохранить и выйти'"
  [confirmButtonText]="'Остаться'"
  (onCancelClicked)="performAction({
    type: DocumentActionTypes.SAVE_AND_MOVE
  })"
  (onConfirmClicked)="performAction({
    type: DocumentActionTypes.CLOSE_AFK_MODAL
  })"
>
  <app-core-stopwatch
    class="w-full flex justify-center"
    type="moveBack"
    [time]="300"
    [stopTime]="0"
  />
  <p class="mt-[8px]">После окончания времени документ автоматически будет сохранен и вы будете перенаправлены в режим просмотра.</p>
</app-core-alert-modal>

<div
  class="w-screen h-screen absolute left-0 top-0 bg-white z-50 flex items-center justify-center"
  *ngIf="state.isLoading"
>
  <mat-spinner
    class="size-[40px]"
  />
</div>

<app-core-alert-modal
  *ngIf="state.alreadyEditModalVisible"
  [title]="'Документ уже редактируется'"
  type="info"
  closeActionType="confirm"
  [confirmButtonText]="'Закрыть'"
  (onConfirmClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
>
  <p>Документ сейчас редактируется другим пользователем. Вы сможете начать редактирование, как только предыдущий редактор закончит работу над документом.</p>

</app-core-alert-modal>
