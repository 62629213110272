import React, { CSSProperties, useEffect, useRef, useState } from "react";
import * as Toolbar from '@radix-ui/react-toolbar';
import { useFloating, offset, flip, shift, inline, autoUpdate } from '@floating-ui/react';
import { ToolbarRenderProps } from "@teamtells/toolbar";
import { Editor, Element, NodeEntry, Range, Transforms} from "slate";
import {
  Blocks,
  findPluginBlockBySelectionPath,
  findSlateBySelectionPath,
  HOTKEYS,
  SlateElement, UI,
  useYooptaTools
} from "@teamtells/editor";
import { buildActionMenuRenderProps } from "@teamtells/action-menu-list";
import { ReactSVG } from "react-svg";
import { HighlightColor } from "../components/highlight-color";
import { YooptaEditorConstants } from "../../../common/yoopta-editor-constants";

const { Overlay, Portal } = UI;

const DEFAULT_MODALS = { link: false, highlight: false, actionMenu: false };
type ModalsState = typeof DEFAULT_MODALS;

const SVG_PATH = YooptaEditorConstants.SVG_PATH + 'toolbar/'

type LinkValues = {
  title?: string;
  url: string;
  target?: string;
  rel?: string;
};

const DEFAULT_LINK_VALUE: LinkValues = {
  title: '',
  url: '',
  target: '_self',
  rel: 'noreferrer',
};

const getLinkEntry = (slate: any) => {
  const [link] = Editor.nodes(slate, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && (n as SlateElement).type === 'link',
  });

  return link;
};

function ToolbarRender ({ activeBlock, editor, toggleHoldToolbar }: ToolbarRenderProps) {
  const [modals, setModals] = useState<ModalsState>({ link: false, highlight: false, actionMenu: false });
  const [linkValues, setLinkValues] = useState<LinkValues>(DEFAULT_LINK_VALUE);
  const lastSelection = useRef<Range | null>(null);

  const tools = useYooptaTools();

  const onChangeModal = (modal: keyof ModalsState, value: boolean) => {
    setModals(() => ({ ...DEFAULT_MODALS, [modal]: value }));
  };

  const { refs: actionMenuRefs, floatingStyles: actionMenuStyles } = useFloating({
    placement: 'bottom-start',
    open: modals.actionMenu,
    onOpenChange: (open) => onChangeModal('actionMenu', open),
    middleware: [inline(), flip(), shift(), offset(14)],
    whileElementsMounted: autoUpdate,
  });

  const { refs: highlightPickerRefs, floatingStyles: highlightPickerStyles } = useFloating({
    placement: 'top-end',
    open: modals.highlight,
    onOpenChange: (open) => onChangeModal('highlight', open),
    middleware: [inline(), flip(), shift(), offset(14)],
    whileElementsMounted: autoUpdate,
  });

  const { refs: linkToolRefs, floatingStyles: linkToolStyles } = useFloating({
    placement: 'top-start',
    open: modals.link,
    onOpenChange: (open) => onChangeModal('link', open),
    middleware: [inline(), flip(), shift(), offset(14)],
    whileElementsMounted: autoUpdate,
  });

  const getItemStyle = (type: any) => ({
    color: editor.formats[type]?.isActive() ? '#0B57D0' : undefined,
  })

  const getAlignStyle = (align: 'left'| 'center' | 'right') => ({
    color: (blockData?.meta?.align || "left") === align ? "#0B57D0" : undefined
  });


  const highlight = editor.formats['highlight'];
  const highlightColors = highlight?.getValue();
  const getHighlightTriggerStyle = (): CSSProperties => {

    return {
      color: highlightColors?.color,
      backgroundColor: highlightColors?.backgroundColor,
      backgroundImage: highlightColors?.backgroundImage,
      WebkitTextFillColor: highlightColors?.webkitTextFillColor,
    };
  };

  const isActiveTriggerModal = (modal: keyof ModalsState) => modals[modal];

  const getModalTriggerStyle = (modal: keyof ModalsState) => ({
    backgroundColor: isActiveTriggerModal(modal) ? '#f4f4f5' : undefined,
  });

  const blockLabel = activeBlock?.options?.display?.title || activeBlock?.type || '';

  const ActionMenu = tools["ActionMenu"];
  const LinkTool = tools["LinkTool"];

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (HOTKEYS.isEscape(e)) {
        setModals(DEFAULT_MODALS);
        toggleHoldToolbar?.(false);
        return;
      }
    };

    if (modals.link) {
      const slate = findSlateBySelectionPath(editor);
      if (!slate || !slate.selection) return;

      lastSelection.current = slate.selection;

      const title = Editor.string(slate, slate?.selection);
      const linkNodeEntry = getLinkEntry(slate);

      if (linkNodeEntry) {
        const [linkNode] = linkNodeEntry as NodeEntry<SlateElement>;
        setLinkValues({ ...linkNode.props, title });
      } else {
        setLinkValues({ ...linkValues, title });
      }
    }

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [editor.selection, editor.children, modals.link]);

  const onUpdateLink = (link: LinkValues) => {
    const slate = findSlateBySelectionPath(editor);
    if (!slate) return;

    Editor.withoutNormalizing(slate, () => {
      if (!slate.selection) return;

      const linkNodeEntry = getLinkEntry(slate);

      if (linkNodeEntry) {
        const [linkNode] = linkNodeEntry as NodeEntry<SlateElement>;
        const updatedNode = { props: { ...linkNode?.props, ...link } };

        Transforms.setNodes<SlateElement>(slate, updatedNode, {
          match: (n) => Element.isElement(n) && (n as SlateElement).type === 'link',
        });

        Editor.insertText(slate, link.title || link.url, { at: slate.selection });
        Transforms.collapse(slate, { edge: 'end' });
      } else {
        const defaultLinkProps: Record<string, unknown> | undefined = editor.plugins?.["LinkPlugin"]?.elements?.["link"]?.props;

        const linkNode = {
          type: 'link',
          children: [{ text: link.title }],
          props: {
            ...link,
            target: defaultLinkProps?.["target"] || link.target || '_self',
            rel: defaultLinkProps?.["rel"] || link.rel || 'noopener noreferrer',
            nodeType: 'inline',
          },
        } as SlateElement;

        Transforms.wrapNodes(slate, linkNode, { split: true, at: slate.selection });
        Transforms.setNodes(
          slate,
          { text: link.title },
          {
            at: slate.selection,
            mode: 'lowest',
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && (n as SlateElement).type === 'link',
          },
        );

        Editor.insertText(slate, link.title || link.url, { at: slate.selection });
        Transforms.collapse(slate, { edge: 'end' });
      }

      editor.applyChanges();
      editor.emit('change', editor.children);

      onChangeModal('link', false);
      setLinkValues(DEFAULT_LINK_VALUE);
      toggleHoldToolbar?.(false);

      // if (lastSelection.current) {
      //   try {
      //     Transforms.select(slate, lastSelection.current);
      //     Transforms.setSelection(slate, lastSelection.current);
      //     lastSelection.current = null;
      //   } catch (error) {}
      // }
    });
  };

  const onDeleteLink = () => {
    const slate = findSlateBySelectionPath(editor);
    if (!slate || !slate.selection) return;
    const linkNodeEntry = getLinkEntry(slate);
    if (linkNodeEntry) {
      Transforms.unwrapNodes(slate, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && (n as SlateElement).type === 'link',
      });
    }
    onChangeModal('link', false);
    setLinkValues(DEFAULT_LINK_VALUE);
    toggleHoldToolbar?.(false);
  };

  const onClickLinkOverlay = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (linkToolRefs.floating.current?.contains(e.target as Node)) return;

    toggleHoldToolbar?.(false);
    setModals(DEFAULT_MODALS);
  };

  const onToggleMark = (format: string) => {
    setModals(DEFAULT_MODALS);
    editor.formats[format].toggle();
  };

  const blockData = findPluginBlockBySelectionPath(editor, { at: editor.selection });

  const onToggleAlign = (align: 'left'| 'center' | 'right') => {
    if (!blockData) return;
    Blocks.updateBlock(editor, blockData.id, { meta: { ...blockData.meta, align: align } });
  };


  const onCloseActionMenu = () => onChangeModal('actionMenu', false);

  const actionMenuRenderProps = buildActionMenuRenderProps({ editor, onClose: onCloseActionMenu, view: 'small' });

  return (
    <Toolbar.Root
      className="yoopta-toolbar-root"
      style={{
        boxShadow: '0px 1px 4px 0px #00000040',
      }}
    >
      <Toolbar.ToggleGroup className="yoopta-toolbar-group" type="single" aria-label="Block formatting">
        <Toolbar.ToggleItem
          className="yoopta-button yoopta-toolbar-item"
          value={blockLabel}
          aria-label={blockLabel}
          ref={actionMenuRefs.setReference}
          onClick={() => onChangeModal('actionMenu', !modals.actionMenu)}
          style={getModalTriggerStyle('actionMenu')}
        >
          <div className={"flex gap-[4px] items-center"}>
            <span className="yoo-toolbar-mr-0">{blockLabel}</span>
            <ReactSVG
              src={SVG_PATH + "drop-down-arrow.svg"}
              style={{
                rotate: (modals.actionMenu ? '180deg' : undefined),
                transition: '0.2s'
              }}
            />
          </div>

          {modals.actionMenu && !!ActionMenu && (
            <Portal id="yoo-toolbar-action-menu-list-portal">
              <div style={actionMenuStyles} ref={actionMenuRefs.setFloating} onClick={(e) => e.stopPropagation()}>
                <ActionMenu {...actionMenuRenderProps} />
              </div>
            </Portal>
          )}
        </Toolbar.ToggleItem>
      </Toolbar.ToggleGroup>
      <Toolbar.Separator className="yoopta-toolbar-separator" />
      <Toolbar.ToggleGroup className="yoopta-toolbar-group" type="single" aria-label="Block formatting">
        <Toolbar.ToggleItem
          className="yoopta-button yoopta-toolbar-item"
          value="LinkTool"
          aria-label="LinkTool"
          ref={linkToolRefs.setReference}
          onClick={() => {
            onChangeModal('link', !modals.link);
            toggleHoldToolbar?.(true);
          }}
          style={getModalTriggerStyle('link')}
        >
          <span className="yoo-toolbar-mr-0">Ссылка</span>
          {modals.link && !!LinkTool && (
            <Portal id="yoo-link-tool-portal">
              <Overlay lockScroll className="z-[100]" onClick={onClickLinkOverlay}>
                <div style={linkToolStyles} ref={linkToolRefs.setFloating}>
                  <LinkTool link={linkValues} onSave={onUpdateLink} onDelete={onDeleteLink} />
                </div>
              </Overlay>
            </Portal>
          )}
        </Toolbar.ToggleItem>
      </Toolbar.ToggleGroup>
      <Toolbar.Separator className="yoopta-toolbar-separator" />
      <Toolbar.ToggleGroup className="yoopta-toolbar-group" type="multiple" aria-label="Text formatting">
        {editor.formats["bold"] && (
          <Toolbar.ToggleItem
            className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
            value="bold"
            aria-label="Bold"
            style={getItemStyle('bold')}
            onClick={() => onToggleMark('bold')}
          >
            <ReactSVG src={ SVG_PATH + 'bold.svg'}/>
          </Toolbar.ToggleItem>
        )}
        {editor.formats["italic"] && (
          <Toolbar.ToggleItem
            className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
            value="italic"
            aria-label="Italic"
            style={getItemStyle('italic')}
            onClick={() => onToggleMark('italic')}
          >
            <ReactSVG src={ SVG_PATH + 'italic.svg'}/>
          </Toolbar.ToggleItem>
        )}
        {editor.formats["underline"] && (
          <Toolbar.ToggleItem
            className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
            value="underline"
            aria-label="Underline"
            style={getItemStyle('underline')}
            onClick={() => onToggleMark('underline')}
          >
            <ReactSVG src={ SVG_PATH + 'underline.svg'}/>
          </Toolbar.ToggleItem>
        )}

        {editor.formats["strike"] && (
          <Toolbar.ToggleItem
            className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
            value="strike"
            aria-label="Strike"
            style={getItemStyle('strike')}
            onClick={() => onToggleMark('strike')}
          >
            <ReactSVG src={ SVG_PATH + 'strikethrough.svg'}/>
          </Toolbar.ToggleItem>
        )}
        {editor.formats["code"] && (
          <Toolbar.ToggleItem
            className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
            value="code"
            aria-label="Code"
            style={getItemStyle('code')}
            onClick={() => onToggleMark('code')}
          >
            <ReactSVG src={ SVG_PATH + 'code.svg'}/>
          </Toolbar.ToggleItem>
        )}
      </Toolbar.ToggleGroup>
      <Toolbar.Separator className="yoopta-toolbar-separator" />
      <Toolbar.ToggleGroup className="yoopta-toolbar-group" type="multiple" aria-label="Text formatting">
        <Toolbar.ToggleItem
          className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
          value="align-left"
          aria-label="Alignment"
          style={ getAlignStyle('left') }
          onClick={() => onToggleAlign('left')}
        >
          <ReactSVG src={ SVG_PATH + 'align-left.svg'}/>
        </Toolbar.ToggleItem>
        <Toolbar.ToggleItem
          className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
          value="align-center"
          aria-label="Alignment"
          style={ getAlignStyle('center') }
          onClick={() => onToggleAlign('center')}
        >
          <ReactSVG src={ SVG_PATH + 'align-center.svg'}/>
        </Toolbar.ToggleItem>
        <Toolbar.ToggleItem
          className="yoopta-button yoopta-toolbar-item yoopta-toggle-item"
          value="align-right"
          aria-label="Alignment"
          style={ getAlignStyle('right') }
          onClick={() => onToggleAlign('right')}
        >
          <ReactSVG src={ SVG_PATH + 'align-right.svg'}/>
        </Toolbar.ToggleItem>
      </Toolbar.ToggleGroup>
      <Toolbar.ToggleGroup className="yoopta-toolbar-group" type="single" aria-label="Block formatting">
        {editor.formats["highlight"] && (
          <>
            {modals.highlight && (
              <HighlightColor
                editor={editor}
                floatingStyles={highlightPickerStyles}
                refs={highlightPickerRefs}
                onClose={() => onChangeModal('highlight', false)}
                highlightColors={highlightColors}
              />
            )}

            <Toolbar.ToggleItem
              className="yoopta-button yoopta-toolbar-item"
              value="highlight"
              aria-label="Highlight"
              style={getHighlightTriggerStyle()}
              ref={highlightPickerRefs.setReference}
              onClick={() => onChangeModal('highlight', !modals.highlight)}
            >
              <div className={"flex gap-[4px] items-center"}>
                <ReactSVG
                  src={SVG_PATH + 'text-color-circle.svg'}
                  className={'size-[18px] rounded-[12px]'}
                />
                <ReactSVG
                  className={'text-tt-text-secondary'}
                  src={SVG_PATH + "drop-down-arrow.svg"}
                  style={{
                    rotate: (modals.highlight ? '180deg' : undefined),
                    transition: '0.2s'
                  }}
                />
              </div>
            </Toolbar.ToggleItem>
          </>
        )}
      </Toolbar.ToggleGroup>
    </Toolbar.Root>
  );
}


export default ToolbarRender;
