import { Component, Input } from "@angular/core";
import { SubscriptionEntity, TariffType } from "../../../domain/subscription-entity";
import { dateToDMonthYString } from "../../../../../../../core/utils/dateToDMonthYString";

@Component({
  selector: 'app-active-subscription',
  templateUrl: './active-subscription.component.html',
  styleUrls: [
    './active-subscription.component.scss',
    '../../../../../common/settings.scss'
  ]
})
export class ActiveSubscriptionComponent {
  @Input() subscription?: SubscriptionEntity;

  isOpen: boolean = false;

  tariffToString(tariff: TariffType): string{
    switch (tariff){
      case TariffType.BASE:
        return 'Базовый'
      case TariffType.BUSINESS:
        return 'Бизнес'
      case TariffType.COMMAND:
        return 'Командный'
      case TariffType.CORPORATE:
        return 'Корпоративный'
    }
  }

  protected readonly dateToDMonthYString = dateToDMonthYString;
}
