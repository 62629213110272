import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentAndTariffComponent } from './presentation/payment-and-tariff.component';
import { ActiveSubscriptionComponent } from './presentation/components/active-subscription/active-subscription.component';
import { ComponentsModule } from "../../../../core/components/components.module";
import {
  SvgArrow,
  SvgFeatureCheckmark, SvgModalXMark, SvgTeamTellsLogo,
  SvgTTLogoXl,
  SvgXMark
} from "../../../../core/components/svg-components/svg.components";
import { TariffListComponent } from "./presentation/components/tariff-list/tariff-list.component";
import { TogglerComponent } from "./presentation/components/toggler/toggler.component";
import { RouterLink } from "@angular/router";
import { SubscriptionFormComponent } from './presentation/components/subscription-form/subscription-form.component';
import { ReceiptComponent } from './presentation/components/receipt/receipt.component';
import { PaymentComponent } from './presentation/components/payment/payment.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TariffFormComponent } from './presentation/components/tariff-form/tariff-form.component';
import { SubscriptionService } from "../../data/subscription.service";
import { FormModalComponent } from './presentation/components/form-modal/form-modal.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { A11yModule } from "@angular/cdk/a11y";

@NgModule({
  declarations: [
    PaymentAndTariffComponent,
    ActiveSubscriptionComponent,
    TariffListComponent,
    TogglerComponent,
    SubscriptionFormComponent,
    ReceiptComponent,
    PaymentComponent,
    TariffFormComponent,
    FormModalComponent
  ],
  exports: [
    ReceiptComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgArrow,
    SvgFeatureCheckmark,
    RouterLink,
    SvgXMark,
    SvgTTLogoXl,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SvgModalXMark,
    SvgTeamTellsLogo,
    A11yModule
  ],
  providers: [
    SubscriptionService
  ]
})
export class PaymentAndTariffModule { }
