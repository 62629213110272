<app-form-modal
  [isLoading]="state.isLoading"
>
  <div
    class="flex flex-col gap-[16px]"
  >
    <div
      class="form-block"
    >
      <p class="form-block-title">Оформление подписки</p>

      <div class="flex flex-col gap-[20px]">
        <app-core-dropdown-field
          label="Выберите тариф"
          variant="standard"
          [selectedItem]="state.currentTariff"
          [items]="state.tariffDropdownItems"
          (onSelect)="performAction({
          type: SubscriptionFormActionTypes.CHANGE_TARIFF,
          tariffId: $event
        })"
        />

        <app-core-dropdown-field
          label="Выберите длительность подписки"
          variant="standard"
          [selectedItem]="state.currentDuration"
          [items]="state.durationDropdownItems"
          (onSelect)="performAction({
          type: SubscriptionFormActionTypes.CHANGE_DURATION,
          durationId: $event
        })"
        />

        <div class="flex justify-between items-center">
          <p class="text-tt-text-main text-tt-font-size_l">Укажите количество пользователей</p>
          <app-input-number
            [value]="state.usersCount"
            (onChange)="performAction({
            type: SubscriptionFormActionTypes.CHANGE_USERS_COUNT,
            value: $event
          })"
          />
        </div>
      </div>
    </div>

    <div
      class="form-block"
    >
      <p class="form-block-title">Дополнительно</p>

      <div class="flex justify-between items-center">
        <p class="text-tt-text-main text-tt-font-size_l">Дополнительные ГБ на диске</p>
        <app-input-number
          [value]="state.memoryCount"
          (onChange)="performAction({
          type: SubscriptionFormActionTypes.CHANGE_ADDITIONAL_MEMORY,
          value: $event
        })"
        />
      </div>
    </div>

  </div>

</app-form-modal>
