import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-task-item",
  templateUrl: "./task-item.component.html",
  styleUrls: ["./task-item.component.scss"]
})
export class TaskItemComponent implements OnChanges {
  @Input() task: TaskEntity = {
    number: 1,
    projectTag: 'TTP',
    title: 'Title',
    description: 'Description',
    priority: TaskPriority.NORMAL,
    type: TaskType.TASK,
  }

  @Input() executor: TaskExecutor = {
    id: 1,
    name: 'name',
    surname: 'surname',
    email: 'ferdayimlu@tozya.com',
  }
  @Input() showType: 'base' | 'hide' = 'base'
  @Input() isSelected: boolean = false;

  @Output() ctrlClicked = new EventEmitter();

  taskTagNumber: string = ''

  protected readonly TaskPriority = TaskPriority;

  onClick(event: MouseEvent) {
    if (event.ctrlKey) {
      this.ctrlClicked.emit()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['task'])
    {
      this.taskTagNumber = this.task.projectTag + '-' + this.task.number
    }
  }
}
export interface TaskEntity{
  number: number,
  projectTag: string
  title: string,
  description: string,
  priority: TaskPriority,
  type: TaskType,
}
export interface TaskExecutor{
  id: number,
  name: string,
  surname: string,
  patronymic?: string,
  email: string,
}

export enum TaskType {TASK, ERROR, BUGFIX}
export enum TaskPriority { NORMAL, CRITICAL}
