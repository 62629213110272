<app-form-modal
  [isLoading]="state.isLoading"
>
  <div
    class="flex flex-col gap-[16px]"
  >
    <div
      class="form-block"
    >
      <div
        class="flex justify-between items-center gap-[10px]"
      >
        <p class="form-block-title">Расширение тарифа</p>
        <app-core-switcher
          [isChecked]="state.isExtensionsOpen"
          (onSwitch)="performAction({
            type: TariffFormActionTypes.CHANGE_IS_EXTENSIONS_OPEN,
            value: !state.isExtensionsOpen
          })"
        />
      </div>

      <div
        class="flex justify-between items-center"
        *ngIf="state.isExtensionsOpen"
      >
        <p class="text-tt-text-main text-tt-font-size_l">Дополнительные пользователи</p>
        <app-input-number
          [value]="state.usersCount"
          (onChange)="performAction({
            type: TariffFormActionTypes.CHANGE_USERS_COUNT,
            value: $event
          })"
        />
      </div>

      <div
        class="flex justify-between items-center"
        *ngIf="state.isExtensionsOpen"
      >
        <p class="text-tt-text-main text-tt-font-size_l">Место на диске, ГБ</p>
        <app-input-number
          [value]="state.memoryCount"
          (onChange)="performAction({
            type: TariffFormActionTypes.CHANGE_ADDITIONAL_MEMORY,
            value: $event
          })"
        />
      </div>

    </div>

    <div
      class="form-block"
    >
      <div class="flex justify-between items-center gap-[10px]">
        <p class="form-block-title">Продление тарифа</p>
        <app-core-switcher
          [isChecked]="state.isDurationOpen"
          (onSwitch)="performAction({
            type: TariffFormActionTypes.CHANGE_IS_DURATION_OPEN,
            value: !state.isDurationOpen
          })"
        />
      </div>

      <app-core-dropdown-field
        *ngIf="state.isDurationOpen"
        label="Выберите длительность подписки"
        variant="standard"
        [selectedItem]="state.currentDuration"
        [items]="state.durationDropDownItems"
        (onSelect)="performAction({
          type: TariffFormActionTypes.CHANGE_DURATION,
          durationId: $event
        })"
      />

    </div>
  </div>
</app-form-modal>
