import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  PaymentAndTariffResultAction,
  PaymentAndTariffResultActionTypes
} from "./payment-and-tariff-result-action";
import { PaymentAndTariffState } from './payment-and-tariff-state';

@Injectable()
export class PaymentAndTariffReducer
  implements Reducer<PaymentAndTariffState, PaymentAndTariffResultAction>
{
  reduce(state: PaymentAndTariffState, action: PaymentAndTariffResultAction): PaymentAndTariffState {
    switch (action.type) {
      case PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_SUBSCRIPTION_FORM:
        return {...state, isSubscriptionFormOpen: action.value}
      case PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_TARIFF_FORM:
        return {...state, isTariffFormOpen: action.value}
      case PaymentAndTariffResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case PaymentAndTariffResultActionTypes.INIT_ACTIVE_SUBSCRIPTION:
        return {...state,
          subscription: action.subscription,
          isEditable: action.isEditable
        }
      case PaymentAndTariffResultActionTypes.INIT_TARIFFS:
        return {...state,
          tariffs: action.tariffs,
          currentTariff: action.currentTariff,
          durationItems: action.durations,
          currentDuration: action.currentDuration
        }
    }
  }
}
