import { ToggleItem } from "../components/toggler/toggler.component";

export class PaymentAndTariffConstants{
  public static PAYMENT_TYPES: ToggleItem[] = [
    {
      id: 'card',
      text: 'Оплата картой'
    },
    {
      id: 'invoice',
      text: 'Оплата по счету'
    },
  ]
}
