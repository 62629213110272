<p
  class="text-black text-tt-font-size_l"
  *ngIf="!subscription"
>
  У вас не подключен ни один тариф
</p>

<div
  *ngIf="subscription"
  class="dropdown-block"
>
  <div class="flex gap-[24px] items-center">
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Ваш план
      </p>
      <p class="subscription-part-value">
        {{ subscription.tariff.name }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Действует до
      </p>
      <p class="subscription-part-value">
        {{ dateToDMonthYString(subscription.expirationDate)  }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Расчетный период
      </p>
      <p class="subscription-part-value">
        {{ dateToDMonthYString(subscription.billingPeriodStart, true) }}
        -
        {{ dateToDMonthYString(subscription.billingPeriodEnd, true) }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        Активные пользователи
      </p>
      <p class="subscription-part-value">
        {{ subscription.activeUsersCount }} / {{ subscription.activeUsersMax }}
      </p>
    </div>

    <button
      class="flex-grow-0 size-[36px] rounded-[8px] shrink-0 bg-[#F0F5FC] flex justify-center items-center"
      (click)="isOpen = !isOpen"
    >
      <svg-arrow class="size-[24px] block text-tt-text-secondary transition-transform"
         [class.rotate-90]="!isOpen"
         [class.rotate-[270deg]]="isOpen"
      />
    </button>
  </div>

  <div
    class="mt-[24px]"
    *ngIf="isOpen"
  >
    <p class="subscription-part-title">
      Место на диске
    </p>
    <p class="subscription-part-value">
      {{ subscription.occupiedStorageSpace }} / {{ subscription.maxStorageSpace }} ГБ
    </p>
  </div>
</div>
