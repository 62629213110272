<div class="py-[44px] mx-auto max-w-[1250px] w-full flex flex-col gap-[16px] justify-center">
  <h2 class="font-medium text-tt-text-secondary text-[20px] leading-[30px]">
    Активный тариф
  </h2>

  <div class="
    bg-white border-[1px] border-tt-border-default rounded-[16px] w-full
    flex gap-[24px] p-[20px]"
  >
    <div class="flex flex-col gap-[16px w-full">
      <p class="font-medium text-[22px] leading-[20px]">Пробный 10 дней</p>
      <p class="mt-[16px] font-normal text-[14px] leading-[18px] text-tt-text-secondary">Идеально подходит как небольшим командам, так и крупным корпорациям.</p>
    </div>

    <div class="w-full flex flex-col gap-[12px]">
      <div class="py-[6px]">
        <div class="flex justify-between">
          <p class="font-medium text-[14px] leading-[18px] text-tt-text-secondary">Срок действия</p>
          <p class="font-normal text-[14px] leading-[18px] text-tt-text-secondary">4 июля 2024 года</p>
        </div>
        <div class="mt-[4px] rounded-[4px] bg-tt-border-default w-full overflow-hidden h-[8px]">
          <div class="bg-tt-primary h-full rounded-[4px]" [style.width] = "45 + '%'"></div>
        </div>
      </div>
      <div class="py-[6px]">
        <div class="flex justify-between">
          <p class="font-medium text-[14px] leading-[18px] text-tt-text-secondary">Кол-во пользователей</p>
          <p class="font-normal text-[14px] leading-[18px] text-tt-text-secondary">1 / 25 максимально</p>
        </div>
        <div class="mt-[4px] rounded-[4px] bg-tt-border-default w-full overflow-hidden h-[8px]">
          <div class="bg-tt-primary h-full rounded-[4px]" [style.width] = "4 + '%'"></div>
        </div>
      </div>
      <div class="py-[6px]">
        <div class="flex justify-between">
          <p class="font-medium text-[14px] leading-[18px] text-tt-text-secondary">Хранилище</p>
          <p class="font-normal text-[14px] leading-[18px] text-tt-text-secondary">0.04 / 25 Гб</p>
        </div>
        <div class="mt-[4px] rounded-[4px] bg-tt-border-default w-full overflow-hidden h-[8px]">
          <div class="bg-tt-primary h-full rounded-[4px]" [style.width] = "0.5 + '%'"></div>
        </div>
      </div>
    </div>
  </div>

  <h2 class="font-medium text-tt-text-secondary text-[20px] leading-[30px]">
    Настройки заказа
  </h2>

  <settings-subscription-order/>

</div>

