<div [ngClass]="className">
  <button
    (click)="onClickValue($event)"
    [disabled]="disabled"
    (focus)="onFocus()"
    class="relative overflow-hidden flex items-center justify-center transition-colors group outline-tt-informer-info-icon"
    [ngClass]="{
      'cursor-pointer': !disabled,
      'size-[48px]': size === 'l',
      'size-[64px]': size === 'xl',
    }"
    #button
  >
    <div
      class="flex justify-center items-center rounded-full text-tt-icon-button-icon-default
        group-hover:bg-tt-icon-button-background-hover group-hover:text-tt-icon-button-icon-hover
        group-active:bg-tt-icon-button-background-focus group-active:text-tt-icon-button-icon-hover
      "
      [ngClass]="{
        'size-[40px]': size === 'l',
        'size-[64px]': size === 'xl',
      }"
    >
      <ng-content />
    </div>

  </button>
</div>
