import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from "@angular/core";

@Component({
  selector: 'app-core-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
})
export class TextareaFieldComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('textarea') textarea!: ElementRef;

  @Input() className: string | string[] = [];
  @Input() placeholder?: string;
  @Input() label?: string;
  @Input() autocomplete?: string;
  @Input() defaultValue = '';
  @Input() readonly: boolean = false
  @Input() value:  string | null = null;
  @Input() error?: string | null;
  @Input() errorShowType: 'onUnFocused' | 'onChange' = 'onChange';
  @Input() required = false;
  @Input() charsCounterMax: number = 0
  @Input() isResizable = true
  @Input() variant: 'outlined' | 'standard' = 'outlined';
  @Input() height?: number;

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  focused?: boolean;
  countChars: number = 0
  localValue: string = '';

  onFocus() {
    this.focused = true;
  }

  onUnFocus() {
    this.focused = false;
  }

  onChangeValue(value: string) {
    this.localValue = value;
    this.onChange.emit(value);
    this.countChars = value.length
    if(this.isResizable) {
      this.adjustTextareaHeight();
    }
  }

  ngAfterViewChecked() {
    if(this.isResizable) {
      this.adjustTextareaHeight();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && !changes['value'].firstChange) {
      this.localValue = this.value ?? '';
      this.countChars = this.localValue.length
    }
  }

  ngOnInit() {
    this.localValue = this.defaultValue;
    this.countChars = this.defaultValue.length
  }

  adjustTextareaHeight() {
    const textarea = this.textarea.nativeElement;
    textarea.style.height = 'auto';
    const computed = window.getComputedStyle(textarea);
    const height =
      parseInt(computed.getPropertyValue('border-top-width')) +
      textarea.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'));
    textarea.style.height = Math.max(height, 112) + 'px';
  }
}
