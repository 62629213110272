<div class="child-routes-width h-[48px] px-[24px] flex justify-between gap-[24px] min-w-[0]"
  [ngClass]="header.styles"
>
  <div
    class="py-[14px] gap-[24px]
      flex flex-[1] items-center font-normal
      text-tt-header-bread-crumbs text-tt-font-size_l truncate">
    <p class="shrink-0">{{header.organisationName}}</p>

    <div class="flex flex-grow">
      <div class="flex items-center hover:text-tt-text-secondary"
        *ngFor="let breadCrumb of header.breadCrumbs; let i = index"
      >
        <p class="overflow-ellipsis" *ngIf="breadCrumb.href == 'none'">
          {{breadCrumb.name}}
        </p>
        <a
          class="overflow-ellipsis"
          [routerLink]="breadCrumb.href"
          *ngIf="breadCrumb.href != 'none'"
        >
          {{breadCrumb.name}}
        </a>
        <svg-breadcrumbs-arrow
          class="text-tt-icon-default"
          *ngIf="i != header.breadCrumbs.length - 1"
        />
      </div>
    </div>
  </div>

  <div class="flex w-max">
    <button class="header-button-size flex justify-center items-center"
      (click)="openPopup()"
    >
      <app-core-avatar
        size="l"
        variant="primary"
        [url]="employee.avatarUrl"
        [placeholder]="employee.userAvatarPlaceholder"
        [bgRGB]="employee.userAvatarColor"
      />
    </button>

    <div
      class="p-[8px] pb-[32px] sm:max-w-[356px] max-w-[250px] w-full rounded-[16px]
        bg-white shadow-tt-shadow-header-popup
        border-[1px] border-tt-border-default"
      *ngIf="isPopupVisible"
      appPopup
      [parentOffset]="{x: 0, y: 0}"
      (popupClose)="isPopupVisible = false"
    >
      <div class="pt-[8px] px-[8px] w-full h-[98px] absolute left-0 top-0">
        <app-str-to-colored-bg
          [str]="employee.email"
        />
      </div>

      <div class="w-full flex justify-center mt-[45px] relative">
        <app-core-avatar
          size="3xl"
          [borderSize]="3"
          variant="primary"
          [url]="employee.avatarUrl"
          [placeholder]="employee.userAvatarPlaceholder"
          [bgRGB]="employee.userAvatarColor"
        />

      </div>

      <div class="mt-[16px] w-full flex flex-col items-center gap-[12px]">
        <p class="font-medium text-[20px] leading-[24px] text-center">
          <span>{{employee.surname}}</span>
          <br *ngIf="employee.patronymic !== undefined && employee.patronymic !==''"/>
          <span> {{employee.name}}</span>
          <span *ngIf="employee.patronymic !== undefined && employee.patronymic !==''"> {{employee.patronymic}}</span>
        </p>

        <p class="text-tt-text-secondary">{{employee.email}}</p>

        <button class="text-tt-text-failure leading-[19px]"
          (click)="logoutClicked.emit()"
        >
          Выйти
        </button>
      </div>

    </div>

  </div>

</div>

