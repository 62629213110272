import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-core-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  host: {
    class: 'w-[35px] h-[22px]'
  }
})
export class SwitcherComponent {
  @Input() isChecked: boolean = false;
  @Output() onSwitch = new EventEmitter();
}
