export type SubscriptionEntity = {
  tariff: SubscriptionTariff
  expirationDate: Date
  billingPeriodStart: Date
  billingPeriodEnd: Date
  activeUsersCount: number
  activeUsersMax: number
  occupiedStorageSpace: number
  maxStorageSpace: number
}

export enum TariffType { BASE, COMMAND, BUSINESS, CORPORATE}
export type SubscriptionTariff = {
  id: string,
  name: string
}
