import { Executor } from "src/app/core/mvi/store";
import { RoleAction, RoleActionTypes } from "./role-action";
import { RoleResultAction, RoleResultActionTypes } from "./role-result-action";
import { Injectable } from "@angular/core";
import { RoleNavItems, RoleState } from "./role-state";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { MainRoutesPaths } from "../../../../../../routes/main-routes";
import { EmployeesRoutesPath } from "../../../../../../routes/employees-routes";
import { EmployeesConstants } from "../../../../common/employees-constants";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class RoleExecutor extends Executor<
  RoleState,
  RoleAction,
  RoleResultAction
> {
  constructor(
    private roleService: RoleService,
    private route: ActivatedRoute,
    private router: Router,
    private toastsService: ToastsService,
  ) {
    super();
  }

  execute(action: RoleAction) {
    switch (action.type) {
      case RoleActionTypes.INIT:
        this.handleInit()
        break
      case RoleActionTypes.CHANGE_NAV_ITEM:
        this.handleChangeNavItem(action.navItem)
        break
      case RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: RoleResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value
        })
        break
      case RoleActionTypes.DELETE:
        if(this.getState().isCustom){
          this.reduce({
            type: RoleResultActionTypes.CHANGE_IS_DELETING,
            value: true
          })
          this.roleService.deleteRole(this.getState().id).subscribe({
            next: () => {
              this.reduce({
                type: RoleResultActionTypes.CHANGE_IS_DELETING,
                value: false
              });
              this.router.navigateByUrl(`/${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.ROLES}`);
            },
            error: err => {
              this.toastsService.createToast({
                title: 'Произошла ошибка при удалении роли',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
              this.reduce({
                type: RoleResultActionTypes.CHANGE_IS_DELETING,
                value: false
              });
            }
          })
        }
        break
      case RoleActionTypes.SAVE_MAIN_DATA:
        this.reduce({
          type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: true
        })
        this.roleService.updateRole(this.getState().id, {
          name: action.data.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
          description: action.data.description.trim().split(' ').filter((str) => str.length > 0).join(' '),
          permissions: this.getState().permissions,
        }).subscribe({
          next: ()=>{
            this.reduce({
              type: RoleResultActionTypes.SAVE_MAIN_DATA,
              mainData: action.data
            });
            this.reduce({
              type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          },
          error: err => {
            this.toastsService.createToast({
              title: 'Произошла ошибка при сохранении изменений',
              description: 'Попробуйте позднее',
              state: ToastState.ERROR
            })
            this.reduce({
              type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          },
        })
        break
      case RoleActionTypes.SAVE_PERMISSIONS:
        this.reduce({
          type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
          value: true
        })
        this.roleService.updateRole(this.getState().id, {
          name: this.getState().mainData.name,
          description: this.getState().mainData.description,
          permissions: action.permissions,
        }).subscribe({
          next: () => {
            this.reduce({
              type: RoleResultActionTypes.SAVE_PERMISSIONS,
              permissions: action.permissions
            });
            this.reduce({
              type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          },
          error: err => {
            this.toastsService.createToast({
              title: 'Произошла ошибка при сохранении изменений',
              description: 'Попробуйте позднее',
              state: ToastState.ERROR
            })
            this.reduce({
              type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING,
              value: false
            })
          }
        })
        break
    }
  }

  handleInit(){
    const id = this.route.snapshot.paramMap.get('id')
    if(id){
      this.reduce({
        type: RoleResultActionTypes.CHANGE_IS_LOADING,
        value: true
      })
      this.roleService.getRole(id).subscribe({
        next: (role) => {
          const newMainData = {
            name: role.name,
            description: role.description
          };
          if (!role.isCustom) {
            const baseRole = EmployeesConstants.BASE_ROLES.get(role.name);
            if (baseRole) {
              newMainData.name = baseRole.name;
              newMainData.description = baseRole.description;
            }
          }
          this.reduce({
            type: RoleResultActionTypes.UPDATE_ROLE,
            id: id,
            isCustom: role.isCustom,
            mainData: newMainData,
            permissions: role.permissions,
            canEdit: role.canEdit,
            canDelete: role.canDelete
          });

          this.reduce({
            type: RoleResultActionTypes.CHANGE_IS_LOADING,
            value: false
          });
        },
        error: err => {
          this.toastsService.createToast({
            title: 'Произошла ошибка при загрузке роли',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
          this.router.navigateByUrl(`/${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.ROLES}`);
        }
      })
    }
  }

  handleChangeNavItem(navItemId: string){
    let navItem = RoleNavItems.MAIN
    switch(navItemId){
      case RoleNavItems.PERMISSIONS:
        navItem = RoleNavItems.PERMISSIONS
        break
      case RoleNavItems.USERS:
        navItem = RoleNavItems.USERS
        break
    }

    this.reduce({
      type: RoleResultActionTypes.CHANGE_NAV_ITEM,
      navItem: navItem
    })
  }
}
