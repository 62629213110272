import { TariffDuration } from "../../domain/tariff-duration";
import { ToggleItem } from "../components/toggler/toggler.component";
import { DropdownItem } from "../../../../../../core/components/fields/dropdown-field/dropdown-field.component";
import { ruNumWord } from "../../../../../../core/utils/ru-num-word";
import { SettingsConstants } from "../../../../common/settings-constants";
import { DurationDto } from "../../../../data/dto/all-tariffs-dto";

export function convertTariffDurationToToggleItems(items: TariffDuration[], prefix: string = 'на'): ToggleItem[]
{
  return items.map<ToggleItem>(item=> {
    let name = ''
    const countYears = item.countMonth / 12
    if(countYears >= 1) {
      name = `${prefix} ${countYears} ${ruNumWord(countYears, SettingsConstants.YEAR_STRINGS )}`
    } else {
      switch (item.countMonth) {
        case 6:
          name = `${prefix} полгода`
          break
        default:
          name = `${prefix} ${item.countMonth} ${ruNumWord(item.countMonth, SettingsConstants.MONTH_STRINGS)}`
          break
      }
    }
    return {
      id: item.countMonth.toString(),
      text: name,
      suffix: item.discountPercent ? ` ${item.discountPercent}% скидка` : undefined,
    }
  })
}

export function convertToDropDownItem(item: TariffDuration): DropdownItem{
  return {
    id: item.countMonth.toString(),
    name: item.name,
    suffix: item.discountPercent ? ` ${item.discountPercent}% скидка` : undefined,
  }
}

export function convertDurationToDropDownItem(item: DurationDto, prefix: string = 'на'): DropdownItem{
  let name = ''
  const countYears = item.duration / 12
  if(countYears >= 1) {
    name = `${prefix} ${countYears} ${ruNumWord(countYears, SettingsConstants.YEAR_STRINGS )}`
  } else {
    switch (item.duration){
      case 6:
        name = `${prefix} полгода`
        break
      default:
        name = `${prefix} ${item.duration} ${ruNumWord(item.duration, SettingsConstants.MONTH_STRINGS )}`
        break
    }
  }

  return {
    id: item.duration.toString(),
    name: name,
    suffix: item.discount ? ` ${item.discount}% скидка` : undefined,
  }
}
