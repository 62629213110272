import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from "./presentation/role.component";
import { RoleMainComponent } from './presentation/components/role-main/role-main.component';
import { RolePermissionsComponent } from './presentation/components/role-permissions/role-permissions.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ComponentsModule } from "../../../../core/components/components.module";
import { SpaceSettingsHome, SvgPerson, SvgTrash } from "../../../../core/components/svg-components/svg.components";
import { EmployeesRolesModule } from "../employees-roles/employees-roles.module";
import { Validator } from "../../../../core/validators/validator";

@NgModule({
  declarations: [
    RoleComponent,
    RoleMainComponent,
    RolePermissionsComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SpaceSettingsHome,
    SvgPerson,
    EmployeesRolesModule,
    SvgTrash,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: 'NewRoleNameValidator',
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleNameValidatorFactory,
    },
    {
      provide: 'NewRoleDescriptionValidator',
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleDescriptionValidatorFactory,
    },
  ]
})
export class RoleModule { }
