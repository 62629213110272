import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ToggleItem } from "../toggler/toggler.component";
import { TariffEntity } from "../../../domain/tariff-entity";
import { calcCurrentPrice } from "../../utils/calc-current-price";
import { TariffDuration } from "../../../domain/tariff-duration";
import { convertTariffDurationToToggleItems } from "../../utils/convert-tariff-duration";

@Component({
  selector: "app-tariff-list",
  templateUrl: "./tariff-list.component.html",
  styleUrls: ["./tariff-list.component.scss"]
})
export class TariffListComponent implements OnChanges {
  @Input() selectedTariff?: TariffEntity;
  @Input() tariffs: TariffEntity[] = []
  @Input() durationItems: TariffDuration[] = []
  @Input() currentDuration: TariffDuration | null = null

  durationToggleItems: ToggleItem[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['durationItems']) {
      this.durationToggleItems = convertTariffDurationToToggleItems(this.durationItems);
    }
  }

  toggleTariffType(id: string){
    const findDuration = this.durationItems.find((item) => item.countMonth === parseInt(id))
    this.currentDuration = findDuration ? findDuration : this.currentDuration
  }

  protected readonly Math = Math;
  protected readonly calcCurrentPrice = calcCurrentPrice;
}

