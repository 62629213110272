<a
  href="https://www.teamtells.ru"
  class="z-10 fixed right-3 top-3 sm:right-7 sm:top-7"
>
  <app-core-button-icon
    size="xl"
  >
    <svg-modal-xmark/>
  </app-core-button-icon>
</a>
<div
  class="bg-tt-bg-page min-h-[100dvh] min-w-[100dvw] flex items-center flex-col justify-center px-2"
>
  <ng-content />
</div>
