import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-core-informer-text',
  templateUrl: './informer-text.component.html',
  styleUrls: ['./informer-text.component.scss']
})
export class InformerTextComponent {
  @Input() type: InformerTextType = 'info'
  @Input() informerText?: string;
}

export type InformerTextType = 'warning' | 'info'
